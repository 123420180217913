import { NEXT_REVALIDATE_ISR_SECS, NEXT_REVALIDATE_SECS } from '@/constants';
import logger from '../logger';
import { envToBool } from '../env';

const isServer = typeof window === 'undefined';

/**
 * Whether isr enablement has been requested, see ENABLE_ISR for the final determination
 */
export const ISR_REQUESTED = envToBool(process.env.NEXT_PUBLIC_ENABLE_ISR);

if (isServer && ISR_REQUESTED && !process.env.SECRET_CACHE_INVALIDATION_KEY) {
  logger.error(
    'Configuration error - NEXT_PUBLIC_ENABLE_ISR is set but SECRET_CACHE_INVALIDATION_KEY is not - disabling isr - cached pages will not be invalidated'
  );
}

/**
 * Is incremental static generation enabled
 */
export const ENABLE_ISR = Boolean(isServer && ISR_REQUESTED && process.env.SECRET_CACHE_INVALIDATION_KEY);

/**
 * Route segment options for ISR, ready to export from server bits
 */
export const dynamic = ENABLE_ISR ? 'force-static' : 'force-dynamic';
export const revalidate = ENABLE_ISR ? NEXT_REVALIDATE_ISR_SECS : NEXT_REVALIDATE_SECS;
export const dynamicParams = true;
