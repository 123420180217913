/* istanbul ignore file */

import isEqual from 'lodash-es/isEqual';
import merge from 'deepmerge';
// From: https://github.com/ivanms1/nextjs-apollo-ssr
import { ApolloClient, InMemoryCache, type NormalizedCacheObject } from '@apollo/client';
import { GRAPHQL_SERVER } from '@/constants';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject> | null;

function createApolloClient() {
  // TODO: JYP - consider switching to nerd wallet cache (it provides ttl, where the stock one does not)
  // https://www.npmjs.com/package/@nerdwallet/apollo-cache-policies?activeTab=readme
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    uri: GRAPHQL_SERVER,
    cache: new InMemoryCache({
      // prevent caching dimension and units values in Size Guide
      // that happens because dimension and units use non-unique ids (Width, Length, Sleeve; inches, cm)
      typePolicies: {
        SizeChartUnitValue: {
          keyFields: false,
        },
        SizeChartDimension: {
          keyFields: false,
        },
        BasicProduct: {
          keyFields: false,
        },
        Product: {
          keyFields: false,
        },
        Products: {
          keyFields: false,
        },
        PrimaryProduct: {
          keyFields: false,
        },
        Size: {
          keyFields: false,
        },
      },
    }),
  });
}

export function initializeApollo(initialState?: any) {
  const localApolloClient = apolloClient ?? createApolloClient();

  if (initialState) {
    const existingCache = localApolloClient.cache.extract();

    const data = merge(initialState, existingCache, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });
    localApolloClient.cache.restore(data);
  }

  if (typeof window === 'undefined') {
    return localApolloClient;
  }

  if (!apolloClient) {
    apolloClient = localApolloClient;
  }

  return localApolloClient;
}

// Amaze modification: added type for props
export type ApolloPageProps = {
  props: {
    [APOLLO_STATE_PROP_NAME]?: NormalizedCacheObject;
  };
};

export function addApolloState(client: ApolloClient<NormalizedCacheObject>, pageProps?: ApolloPageProps) {
  if (pageProps?.props) {
    // eslint-disable-next-line no-param-reassign
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}
