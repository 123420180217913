// tyepscript utilities

/**
 * Convert specified members to required, like Required<> but for a specified list of properties
 *
 * interface Example { x: number, y?: number, z?: number }l
 * type ExampleReq = RequiredSome<Example, 'y' | 'z'>;
 * // effective output: interface ExampleReq { x: number, y: number, z: number } [note the missing question marks]
 *
 * based on https://stackoverflow.com/a/54178819
 */
export type RequiredSome<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

/**
 * Convert specified members to partial, like Partial<> but for a specified list of properties
 *
 */
export type PartialSome<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

/**
 * Extract type of first parameter to a function
 */
export type FunctionParameter<T> = T extends (arg: infer A) => unknown ? A : never;

/**
 * Define type in which all fields are required (recursively)
 *
 * from: https://stackoverflow.com/questions/57835286/deep-recursive-requiredt-on-specific-properties
 */
export type DeepRequired<T> = { [K in keyof T]-?: DeepRequired<T[K]> };

/**
 * Compile time type equality check
 *
 * *** NOTE: doest not work if X or Y are any ***
 *
 * from https://github.com/Microsoft/TypeScript/issues/27024#issuecomment-421529650
 */
export type TypeEquals<X, Y> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? true : false;

/**
 * Compile time asset generic, won't compile if T is false
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type StaticAssert<T extends true> = never;

/**
 * Function to call in unreachable location to get compile time errors if it becomes reachable, will throw
 * if called.
 * from: https://gist.github.com/JanMalch/66f642ab22c96aae060c7029fbe54d68
 *
 * @param value The value which should have been handled in some exaustive way earlier
 * @param message [optional]  Message to throw in an Error(), have to throw as the return type is never
 */
export function unreachable(value: never, message: string = `Unreachable value reached: ${value}`): never {
  throw new Error(message);
}

/**
 * Version of unreachable() which returns a default value instead of throwing, so
 * default behavior can be maintained while (hopefully) still breaking the next build.
 * Useful for exaustive checks on switch statments where you want to return something
 * if the case isn't coverd (like in a type guard)
 *
 * @param value The value which should have been handled in some exaustive way earlier
 * @param defaultValue The return value
 * @returns defaultValue
 */
export function safeUnreachable<T>(value: never, defaultValue: T): T {
  return defaultValue;
}
