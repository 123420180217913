import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/** THIS FILE IS AUTO-GENERATED **/
/** DO NOT EDIT **/

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export interface AddressInput {
  readonly address1?: InputMaybe<Scalars['String']['input']>;
  readonly address2?: InputMaybe<Scalars['String']['input']>;
  readonly city?: InputMaybe<Scalars['String']['input']>;
  readonly country?: InputMaybe<Scalars['String']['input']>;
  readonly countryCode?: InputMaybe<Scalars['String']['input']>;
  readonly state?: InputMaybe<Scalars['String']['input']>;
  readonly stateCode?: InputMaybe<Scalars['String']['input']>;
  readonly zip?: InputMaybe<Scalars['String']['input']>;
}

export interface BillingDetails {
  readonly address: StripeAddressInput;
  readonly name?: InputMaybe<Scalars['String']['input']>;
}

export interface CheckoutDataInput {
  readonly affiliate?: InputMaybe<Scalars['String']['input']>;
  readonly currency?: InputMaybe<Currency>;
  readonly customer?: InputMaybe<CustomerInput>;
  readonly deliveryOption?: InputMaybe<Scalars['String']['input']>;
  readonly lineItems?: InputMaybe<ReadonlyArray<LineItemFulfillmentDetailsInput>>;
  readonly promoCode?: InputMaybe<Scalars['String']['input']>;
  readonly storeSlug?: InputMaybe<Scalars['String']['input']>;
  readonly trafficSource?: InputMaybe<Scalars['String']['input']>;
}

export type CheckoutDeliveryOption = 'fullRushCost' | 'fullStandardCost' | 'fullSuperRushCost';

export type CheckoutErrorReason = 'BAD_REQUEST' | 'INTERNAL_SERVER' | 'NOT_FOUND' | 'UKNOWN';

export type CheckoutState = 'completed' | 'created' | 'failure' | 'pending' | 'success';

export interface CreatePaymentIntentInput {
  readonly amount?: InputMaybe<Scalars['Float']['input']>;
  readonly checkoutId?: InputMaybe<Scalars['String']['input']>;
  readonly currency?: InputMaybe<Scalars['String']['input']>;
  readonly orderLookupNumber?: InputMaybe<Scalars['String']['input']>;
  readonly storeName?: InputMaybe<Scalars['String']['input']>;
}

// INR manually removed after codegen as rails don't support it yet
export type Currency = 'AUD' | 'CAD' | 'EUR' | 'GBP' /*| 'INR' */ | 'USD';

export interface CustomerInput {
  readonly address?: InputMaybe<AddressInput>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  readonly paymentVendorCustomerId?: InputMaybe<Scalars['String']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
}

export type DeliveryOption = 'rush' | 'standard' | 'super_rush';

export interface DiscountInput {
  readonly code?: InputMaybe<Scalars['String']['input']>;
  readonly currency?: InputMaybe<Currency>;
  readonly percentage?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<DiscountType>;
  readonly value?: InputMaybe<Scalars['Float']['input']>;
}

export type DiscountType = 'fixed_value' | 'free_shipping' | 'percentage';

export interface FulfillmentDetailsInput {
  readonly productionTechniques?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
}

export interface FullfillmentDetailsInput {
  readonly productionTechniques?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
}

export interface GetDeliveryOptionsInput {
  readonly countryCode?: InputMaybe<Scalars['String']['input']>;
  readonly currency?: InputMaybe<Currency>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly lineItems?: InputMaybe<ReadonlyArray<LineItemDetailInput>>;
  readonly stateCode?: InputMaybe<Scalars['String']['input']>;
  readonly storeSlug?: InputMaybe<Scalars['String']['input']>;
}

export interface InitiatePaymentIntentInput {
  readonly amount?: InputMaybe<MoneyInput>;
  readonly billingDetails?: InputMaybe<BillingDetails>;
  readonly captcha?: InputMaybe<Scalars['String']['input']>;
  readonly checkoutId?: InputMaybe<Scalars['String']['input']>;
  readonly customer?: InputMaybe<CustomerInput>;
  readonly lookupNumber?: InputMaybe<Scalars['String']['input']>;
  readonly paymentMethod?: InputMaybe<Scalars['String']['input']>;
  readonly paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  readonly platform: PaymentPlatform;
  readonly storeName?: InputMaybe<Scalars['String']['input']>;
  readonly storeSlug?: InputMaybe<Scalars['String']['input']>;
}

export interface LineItemDetailInput {
  readonly buyerVariantId?: InputMaybe<Scalars['String']['input']>;
  readonly currency?: InputMaybe<MoneyInput>;
  readonly discount?: InputMaybe<DiscountInput>;
  readonly fulfillmentDetails?: InputMaybe<FullfillmentDetailsInput>;
  readonly productVariantId?: InputMaybe<Scalars['String']['input']>;
  readonly quantity?: InputMaybe<Scalars['Float']['input']>;
  readonly shipping?: InputMaybe<MoneyInput>;
  readonly subtotal?: InputMaybe<MoneyInput>;
  readonly tax?: InputMaybe<MoneyInput>;
  readonly teespringID?: InputMaybe<Scalars['String']['input']>;
  readonly unitPrice?: InputMaybe<MoneyInput>;
}

export interface LineItemFulfillmentDetailsInput {
  readonly fulfillmentDetails?: InputMaybe<FulfillmentDetailsInput>;
  readonly quantity?: InputMaybe<Scalars['Float']['input']>;
  readonly sku?: InputMaybe<Scalars['String']['input']>;
}

export interface MoneyInput {
  readonly currency?: InputMaybe<Currency>;
  readonly value?: InputMaybe<Scalars['Float']['input']>;
}

export type PaymentErrorReason = 'BAD_REQUEST' | 'INTERNAL_SERVER' | 'UKNOWN';

export type PaymentPlatform = 'paypal' | 'stripe';

export type ProductVisibilityInput = 'any';

export interface StripeAddressInput {
  readonly city?: InputMaybe<Scalars['String']['input']>;
  readonly country?: InputMaybe<Scalars['String']['input']>;
  readonly line1?: InputMaybe<Scalars['String']['input']>;
  readonly line2?: InputMaybe<Scalars['String']['input']>;
  readonly postal_code?: InputMaybe<Scalars['String']['input']>;
  readonly state?: InputMaybe<Scalars['String']['input']>;
  readonly stateCode?: InputMaybe<Scalars['String']['input']>;
  readonly zip?: InputMaybe<Scalars['String']['input']>;
}

export interface SubmitCheckoutInput {
  readonly checkoutId?: InputMaybe<Scalars['String']['input']>;
  readonly paymentId?: InputMaybe<Scalars['String']['input']>;
  readonly paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  readonly paymentToken?: InputMaybe<Scalars['String']['input']>;
  readonly platform?: InputMaybe<PaymentPlatform>;
  readonly returnUrl?: InputMaybe<Scalars['String']['input']>;
  readonly storeSlug?: InputMaybe<Scalars['String']['input']>;
}

export interface SubmitExpressCheckoutInput {
  readonly checkoutId: Scalars['String']['input'];
  readonly confirmationTokenId: Scalars['String']['input'];
  readonly storeName: Scalars['String']['input'];
  readonly storeSlug: Scalars['String']['input'];
}

export interface SubscriptionItem {
  readonly count: Scalars['Float']['input'];
  readonly currency: Scalars['String']['input'];
  readonly interval: Scalars['String']['input'];
  readonly product_id: Scalars['String']['input'];
  readonly quantity: Scalars['Float']['input'];
}

export interface UpdateCheckoutInput {
  readonly data?: InputMaybe<CheckoutDataInput>;
  readonly id: Scalars['String']['input'];
}

export interface UpdatePaymentIntentInput {
  readonly amount: MoneyInput;
  readonly billingDetails?: InputMaybe<BillingDetails>;
  readonly checkoutId: Scalars['String']['input'];
  readonly customer?: InputMaybe<CustomerInput>;
  readonly id: Scalars['String']['input'];
  readonly paymentMethod: Scalars['String']['input'];
  readonly paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  readonly paymentToken?: InputMaybe<Scalars['String']['input']>;
  readonly platform?: InputMaybe<PaymentPlatform>;
  readonly storeSlug?: InputMaybe<Scalars['String']['input']>;
}

type CreatePaymentIntentResponseFragment_CreatePaymentIntentResult = {
  readonly __typename?: 'CreatePaymentIntentResult';
  readonly paymentId: string;
  readonly paymentToken: string;
  readonly clientSeceret: string;
};

type CreatePaymentIntentResponseFragment_PaymentIntentErrorResult = {
  readonly __typename?: 'PaymentIntentErrorResult';
  readonly reason: PaymentErrorReason;
  readonly context?: string | null;
};

export type CreatePaymentIntentResponseFragment =
  | CreatePaymentIntentResponseFragment_CreatePaymentIntentResult
  | CreatePaymentIntentResponseFragment_PaymentIntentErrorResult;

export type CreatePaymentIntentResultFragment = {
  readonly __typename?: 'CreatePaymentIntentResult';
  readonly paymentId: string;
  readonly paymentToken: string;
  readonly clientSeceret: string;
};

type InitiatePaymentIntentResponseFragment_InitiatePaymentIntentResult = {
  readonly __typename?: 'InitiatePaymentIntentResult';
  readonly paymentId: string;
  readonly paymentToken: string;
};

type InitiatePaymentIntentResponseFragment_PaymentIntentErrorResult = {
  readonly __typename?: 'PaymentIntentErrorResult';
  readonly reason: PaymentErrorReason;
  readonly context?: string | null;
};

export type InitiatePaymentIntentResponseFragment =
  | InitiatePaymentIntentResponseFragment_InitiatePaymentIntentResult
  | InitiatePaymentIntentResponseFragment_PaymentIntentErrorResult;

export type InitiatePaymentIntentResultFragment = {
  readonly __typename?: 'InitiatePaymentIntentResult';
  readonly paymentId: string;
  readonly paymentToken: string;
};

export type PaymentIntentErrorResultFragment = {
  readonly __typename?: 'PaymentIntentErrorResult';
  readonly reason: PaymentErrorReason;
  readonly context?: string | null;
};

type UpdatePaymentIntentResponseFragment_PaymentIntentErrorResult = {
  readonly __typename?: 'PaymentIntentErrorResult';
  readonly reason: PaymentErrorReason;
  readonly context?: string | null;
};

type UpdatePaymentIntentResponseFragment_UpdatePaymentIntentResult = {
  readonly __typename?: 'UpdatePaymentIntentResult';
  readonly paymentId: string;
  readonly paymentToken: string;
};

export type UpdatePaymentIntentResponseFragment =
  | UpdatePaymentIntentResponseFragment_PaymentIntentErrorResult
  | UpdatePaymentIntentResponseFragment_UpdatePaymentIntentResult;

export type UpdatePaymentIntentResultFragment = {
  readonly __typename?: 'UpdatePaymentIntentResult';
  readonly paymentId: string;
  readonly paymentToken: string;
};

export type ProductBlankQueryErrorResultFragment = {
  readonly __typename?: 'ProductBlankQueryErrorResult';
  readonly errors: { readonly __typename?: 'ProductBlankQueryErrors'; readonly product?: ReadonlyArray<string> | null };
};

export type ProductBlankQueryErrorsFragment = {
  readonly __typename?: 'ProductBlankQueryErrors';
  readonly product?: ReadonlyArray<string> | null;
};

export type ProductBlankFragment = {
  readonly __typename?: 'ProductBlank';
  readonly materials?: string | null;
  readonly sizeChartUrl?: string | null;
  readonly fit?: string | null;
  readonly description?: string | null;
  readonly sizeChart?: {
    readonly __typename?: 'SizeChart';
    readonly sizes?: ReadonlyArray<{
      readonly __typename?: 'SizeChartSize';
      readonly id?: string | null;
      readonly dimensions?: ReadonlyArray<{
        readonly __typename?: 'SizeChartDimension';
        readonly id?: string | null;
        readonly units?: ReadonlyArray<{
          readonly __typename?: 'SizeChartUnitValue';
          readonly id?: string | null;
          readonly value?: string | null;
        }> | null;
      }> | null;
    }> | null;
  } | null;
};

export type SizeChartSizeFragment = {
  readonly __typename?: 'SizeChartSize';
  readonly id?: string | null;
  readonly dimensions?: ReadonlyArray<{
    readonly __typename?: 'SizeChartDimension';
    readonly id?: string | null;
    readonly units?: ReadonlyArray<{
      readonly __typename?: 'SizeChartUnitValue';
      readonly id?: string | null;
      readonly value?: string | null;
    }> | null;
  }> | null;
};

export type SizeChartFragment = {
  readonly __typename?: 'SizeChart';
  readonly sizes?: ReadonlyArray<{
    readonly __typename?: 'SizeChartSize';
    readonly id?: string | null;
    readonly dimensions?: ReadonlyArray<{
      readonly __typename?: 'SizeChartDimension';
      readonly id?: string | null;
      readonly units?: ReadonlyArray<{
        readonly __typename?: 'SizeChartUnitValue';
        readonly id?: string | null;
        readonly value?: string | null;
      }> | null;
    }> | null;
  }> | null;
};

export type AddressVerificationFragment = {
  readonly __typename?: 'AddressVerificationResponse';
  readonly addressValid: boolean;
  readonly error?: string | null;
  readonly corrections?: ReadonlyArray<{
    readonly __typename?: 'AddressVerification';
    readonly delivery_line_1?: string | null;
    readonly last_line?: string | null;
    readonly components?: {
      readonly __typename?: 'AddressComponents';
      readonly primary_number?: string | null;
      readonly street_name?: string | null;
      readonly street_suffix?: string | null;
      readonly city_name?: string | null;
      readonly default_city_name?: string | null;
      readonly state_abbreviation?: string | null;
      readonly zipcode?: string | null;
      readonly plus4_code?: string | null;
    } | null;
  }> | null;
};

export type CheckoutErrorFragment = {
  readonly __typename?: 'CheckoutError';
  readonly reason: CheckoutErrorReason;
  readonly message?: string | null;
  readonly displayMessage?: string | null;
};

export type CheckoutUnprocessableErrorFragment = {
  readonly __typename?: 'CheckoutUnprocessableError';
  readonly code: string;
  readonly source: { readonly __typename?: 'CheckoutUnprocessableErrorSource'; readonly pointer: string };
};

export type CheckoutUnprocessableErrorsFragment = {
  readonly __typename?: 'CheckoutUnprocessableErrors';
  readonly errors: ReadonlyArray<{
    readonly __typename?: 'CheckoutUnprocessableError';
    readonly code: string;
    readonly source: { readonly __typename?: 'CheckoutUnprocessableErrorSource'; readonly pointer: string };
  }>;
};

export type CheckoutFragment = {
  readonly __typename?: 'Checkout';
  readonly id?: string | null;
  readonly state?: CheckoutState | null;
  readonly deliveryOption?: CheckoutDeliveryOption | null;
  readonly orderLookupNumber?: string | null;
  readonly promoCode?: string | null;
  readonly lineItems?: ReadonlyArray<{
    readonly __typename?: 'LineItemDetail';
    readonly quantity?: number | null;
    readonly teespringID?: string | null;
    readonly buyerVariantId?: string | null;
    readonly productVariantId?: string | null;
    readonly shipping?: {
      readonly __typename?: 'Money';
      readonly currency?: Currency | null;
      readonly value?: number | null;
    } | null;
    readonly discount?: {
      readonly __typename?: 'Discount';
      readonly currency?: Currency | null;
      readonly value?: number | null;
    } | null;
    readonly currency?: {
      readonly __typename?: 'Money';
      readonly currency?: Currency | null;
      readonly value?: number | null;
    } | null;
    readonly tax?: {
      readonly __typename?: 'Money';
      readonly currency?: Currency | null;
      readonly value?: number | null;
    } | null;
    readonly subtotal?: {
      readonly __typename?: 'Money';
      readonly currency?: Currency | null;
      readonly value?: number | null;
    } | null;
    readonly unitPrice?: {
      readonly __typename?: 'Money';
      readonly currency?: Currency | null;
      readonly value?: number | null;
    } | null;
    readonly fulfillmentDetails?: {
      readonly __typename?: 'FulfillmentDetails';
      readonly productionTechniques?: ReadonlyArray<string> | null;
    } | null;
  }> | null;
  readonly costs?: {
    readonly __typename?: 'Cost';
    readonly shipping?: {
      readonly __typename?: 'Money';
      readonly currency?: Currency | null;
      readonly value?: number | null;
    } | null;
    readonly total?: {
      readonly __typename?: 'Money';
      readonly currency?: Currency | null;
      readonly value?: number | null;
    } | null;
    readonly tax?: {
      readonly __typename?: 'Money';
      readonly currency?: Currency | null;
      readonly value?: number | null;
    } | null;
    readonly discount?: {
      readonly __typename?: 'Discount';
      readonly currency?: Currency | null;
      readonly value?: number | null;
    } | null;
    readonly vat?: {
      readonly __typename?: 'Money';
      readonly currency?: Currency | null;
      readonly value?: number | null;
    } | null;
    readonly subtotal?: {
      readonly __typename?: 'Money';
      readonly currency?: Currency | null;
      readonly value?: number | null;
    } | null;
    readonly currency?: {
      readonly __typename?: 'Money';
      readonly currency?: Currency | null;
      readonly value?: number | null;
    } | null;
  } | null;
};

export type CostsFragment = {
  readonly __typename?: 'Cost';
  readonly shipping?: {
    readonly __typename?: 'Money';
    readonly currency?: Currency | null;
    readonly value?: number | null;
  } | null;
  readonly total?: {
    readonly __typename?: 'Money';
    readonly currency?: Currency | null;
    readonly value?: number | null;
  } | null;
  readonly tax?: {
    readonly __typename?: 'Money';
    readonly currency?: Currency | null;
    readonly value?: number | null;
  } | null;
  readonly discount?: {
    readonly __typename?: 'Discount';
    readonly currency?: Currency | null;
    readonly value?: number | null;
  } | null;
  readonly vat?: {
    readonly __typename?: 'Money';
    readonly currency?: Currency | null;
    readonly value?: number | null;
  } | null;
  readonly subtotal?: {
    readonly __typename?: 'Money';
    readonly currency?: Currency | null;
    readonly value?: number | null;
  } | null;
  readonly currency?: {
    readonly __typename?: 'Money';
    readonly currency?: Currency | null;
    readonly value?: number | null;
  } | null;
};

export type DeliveryMinervaErrorsFragment = {
  readonly __typename?: 'DeliveryMinervaErrors';
  readonly errors?: ReadonlyArray<{
    readonly __typename?: 'DeliveryMinervaError';
    readonly title?: string | null;
    readonly status?: string | null;
    readonly detail?: string | null;
    readonly code?: string | null;
  }> | null;
};

export type DeliveryOptionsFragment = {
  readonly __typename?: 'DeliveryOptions';
  readonly attributes: {
    readonly __typename?: 'DeliveryOptionsAttributes';
    readonly items: ReadonlyArray<{
      readonly __typename?: 'DeliveryOptionsAttributesItem';
      readonly buyerVariant: string;
      readonly quantity?: number | null;
      readonly standardShippingCost?: {
        readonly __typename?: 'DeliveryOptionsCost';
        readonly currency: Currency;
        readonly amount: number;
      } | null;
    }>;
    readonly quotes: ReadonlyArray<{
      readonly __typename?: 'DeliveryOptionsAttributesQuote';
      readonly name?: DeliveryOption | null;
      readonly cost: {
        readonly __typename?: 'DeliveryOptionsCost';
        readonly currency: Currency;
        readonly amount: number;
      };
      readonly deliveryEstimates?: {
        readonly __typename?: 'DeliveryEstimates';
        readonly early: string;
        readonly late: string;
      } | null;
    }>;
    readonly shipments: ReadonlyArray<{
      readonly __typename?: 'DeliveryOptionsAttributesShipment';
      readonly items: ReadonlyArray<{
        readonly __typename?: 'DeliveryOptionsAttributesItem';
        readonly buyerVariant: string;
        readonly quantity?: number | null;
        readonly standardShippingCost?: {
          readonly __typename?: 'DeliveryOptionsCost';
          readonly currency: Currency;
          readonly amount: number;
        } | null;
      }>;
      readonly quotes?: ReadonlyArray<{
        readonly __typename?: 'DeliveryOptionsAttributesQuote';
        readonly name?: DeliveryOption | null;
        readonly cost: {
          readonly __typename?: 'DeliveryOptionsCost';
          readonly currency: Currency;
          readonly amount: number;
        };
        readonly deliveryEstimates?: {
          readonly __typename?: 'DeliveryEstimates';
          readonly early: string;
          readonly late: string;
        } | null;
      }> | null;
    }>;
  };
};

export type CollectionItemFragment = {
  readonly __typename?: 'CollectionItem';
  readonly name?: string | null;
  readonly slug?: string | null;
};

export type CollectionFragment = {
  readonly __typename?: 'Collection';
  readonly name?: string | null;
  readonly slug?: string | null;
  readonly collections?: ReadonlyArray<{
    readonly __typename?: 'CollectionItem';
    readonly name?: string | null;
    readonly slug?: string | null;
  }> | null;
};

export type CollectionsQueryErrorResultFragment = {
  readonly __typename?: 'CollectionsQueryErrorResult';
  readonly errors: { readonly __typename?: 'CollectionsQueryErrors'; readonly store: ReadonlyArray<string> };
};

export type CollectionsV2Fragment = {
  readonly __typename?: 'CollectionsV2';
  readonly storeId?: string | null;
  readonly storeSlug?: string | null;
  readonly collections?: ReadonlyArray<{
    readonly __typename?: 'Collection';
    readonly name?: string | null;
    readonly slug?: string | null;
    readonly collections?: ReadonlyArray<{
      readonly __typename?: 'CollectionItem';
      readonly name?: string | null;
      readonly slug?: string | null;
    }> | null;
  }> | null;
  readonly customCollections?: ReadonlyArray<{
    readonly __typename?: 'Collection';
    readonly name?: string | null;
    readonly slug?: string | null;
    readonly collections?: ReadonlyArray<{
      readonly __typename?: 'CollectionItem';
      readonly name?: string | null;
      readonly slug?: string | null;
    }> | null;
  }> | null;
};

export type CollectionsFragment = {
  readonly __typename?: 'Collections';
  readonly storeId?: string | null;
  readonly storeSlug?: string | null;
  readonly collections?: ReadonlyArray<{
    readonly __typename?: 'Collection';
    readonly name?: string | null;
    readonly slug?: string | null;
    readonly collections?: ReadonlyArray<{
      readonly __typename?: 'CollectionItem';
      readonly name?: string | null;
      readonly slug?: string | null;
    }> | null;
  }> | null;
};

export type StoreDataFragment = {
  readonly __typename?: 'Store';
  readonly id?: string | null;
  readonly name?: string | null;
  readonly slug?: string | null;
  readonly description?: string | null;
  readonly faviconUrl?: string | null;
  readonly logoUrl?: string | null;
  readonly sellerId?: number | null;
  readonly sellerToken?: string | null;
  readonly logoWidth?: number | null;
  readonly logoHeight?: number | null;
  readonly useLogo?: boolean | null;
  readonly themeColor?: string | null;
  readonly navPrimary?: ReadonlyArray<string> | null;
  readonly bannerUrl?: string | null;
  readonly linkColor?: string | null;
  readonly storeErrors?: string | null;
  readonly socialIdentities?: { readonly __typename?: 'SocialIdentity'; readonly twitter?: string | null } | null;
  readonly marketingPixels?: {
    readonly __typename?: 'MarketingPixel';
    readonly googleAnalytics?: string | null;
    readonly googleAdwords?: string | null;
    readonly googleAdwordsLabel?: string | null;
    readonly twitter?: string | null;
    readonly pinterest?: string | null;
    readonly facebook?: string | null;
    readonly facebookCustomCategory?: string | null;
    readonly gmcMerchantId?: string | null;
  } | null;
  readonly promoCodes?: ReadonlyArray<
    | {
        readonly __typename?: 'PromoCodeFixedValue';
        readonly code: string;
        readonly type: DiscountType;
        readonly value: {
          readonly __typename?: 'PromoCodeValue';
          readonly amount?: number | null;
          readonly currency?: Currency | null;
        };
      }
    | { readonly __typename?: 'PromoCodeFreeShipping'; readonly code: string; readonly type: DiscountType }
    | {
        readonly __typename?: 'PromoCodePercentage';
        readonly code: string;
        readonly type: DiscountType;
        readonly percentage: number;
      }
  > | null;
};

export type StorePromoCodeFixedValueFragment = {
  readonly __typename?: 'PromoCodeFixedValue';
  readonly code: string;
  readonly type: DiscountType;
  readonly value: {
    readonly __typename?: 'PromoCodeValue';
    readonly amount?: number | null;
    readonly currency?: Currency | null;
  };
};

export type StorePromoCodeFreeShippingFragment = {
  readonly __typename?: 'PromoCodeFreeShipping';
  readonly code: string;
  readonly type: DiscountType;
};

export type StorePromoCodePercentageFragment = {
  readonly __typename?: 'PromoCodePercentage';
  readonly code: string;
  readonly type: DiscountType;
  readonly percentage: number;
};

export type StoreQueryErrorResultFragment = {
  readonly __typename?: 'StoreQueryErrorResult';
  readonly errors: { readonly __typename?: 'StoreQueryErrors'; readonly store: ReadonlyArray<string> };
};

export type AdditionalImageFragment = {
  readonly __typename?: 'AdditionalImage';
  readonly side?: string | null;
  readonly src?: string | null;
};

export type BasicProductFragment = {
  readonly __typename?: 'BasicProduct';
  readonly imageUrl?: string | null;
  readonly id?: number | null;
  readonly name?: string | null;
  readonly price?: string | null;
  readonly productGroupName?: string | null;
  readonly productName?: string | null;
  readonly url?: string | null;
  readonly listingSlug?: string | null;
  readonly orders?: number | null;
  readonly listingId?: number | null;
  readonly additionalImages?: ReadonlyArray<{
    readonly __typename?: 'AdditionalImage';
    readonly side?: string | null;
    readonly src?: string | null;
  }> | null;
  readonly collections?: ReadonlyArray<{
    readonly __typename?: 'ProductCollection';
    readonly id?: number | null;
    readonly name?: string | null;
    readonly slug?: string | null;
  }> | null;
};

export type BasicProductsFragment = {
  readonly __typename?: 'Products';
  readonly collection?: string | null;
  readonly count?: number | null;
  readonly total_count?: number | null;
  readonly per_page?: number | null;
  readonly page?: number | null;
  readonly next?: string | null;
  readonly basicProducts?: ReadonlyArray<{
    readonly __typename?: 'BasicProduct';
    readonly imageUrl?: string | null;
    readonly id?: number | null;
    readonly name?: string | null;
    readonly price?: string | null;
    readonly productGroupName?: string | null;
    readonly productName?: string | null;
    readonly url?: string | null;
    readonly listingSlug?: string | null;
    readonly orders?: number | null;
    readonly listingId?: number | null;
    readonly additionalImages?: ReadonlyArray<{
      readonly __typename?: 'AdditionalImage';
      readonly side?: string | null;
      readonly src?: string | null;
    }> | null;
    readonly collections?: ReadonlyArray<{
      readonly __typename?: 'ProductCollection';
      readonly id?: number | null;
      readonly name?: string | null;
      readonly slug?: string | null;
    }> | null;
  }> | null;
};

export type ImageFragment = {
  readonly __typename?: 'Image';
  readonly label?: string | null;
  readonly src?: string | null;
  readonly full?: string | null;
  readonly small?: string | null;
  readonly thumbLarge?: string | null;
  readonly thumbSmall?: string | null;
};

export type ListingThumbnailsFragment = {
  readonly __typename?: 'ListingThumbnails';
  readonly primary?: string | null;
  readonly front?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
  readonly back?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
  readonly left?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
  readonly right?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
};

export type PrimaryProductFragment = {
  readonly __typename?: 'PrimaryProduct';
  readonly productType?: string | null;
  readonly productId?: number | null;
  readonly price?: string | null;
  readonly inventoryCount?: number | null;
  readonly currency?: Currency | null;
  readonly color?: string | null;
  readonly teespringId?: number | null;
  readonly variationId?: number | null;
  readonly productionEndDate?: string | null;
  readonly thumbnail?: {
    readonly __typename?: 'Thumbnails';
    readonly back?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
    readonly front?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
    readonly left?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
    readonly right?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
  } | null;
  readonly sizes?: ReadonlyArray<{
    readonly __typename?: 'Size';
    readonly id?: number | null;
    readonly label?: string | null;
    readonly price?: string | null;
  }> | null;
  readonly images?: ReadonlyArray<{
    readonly __typename?: 'Image';
    readonly label?: string | null;
    readonly src?: string | null;
    readonly full?: string | null;
    readonly small?: string | null;
    readonly thumbLarge?: string | null;
    readonly thumbSmall?: string | null;
  }> | null;
  readonly availableSizes?: ReadonlyArray<{
    readonly __typename?: 'Size';
    readonly id?: number | null;
    readonly label?: string | null;
    readonly price?: string | null;
  }> | null;
  readonly attributes?: { readonly __typename?: 'Attributes'; readonly hex?: string | null } | null;
};

export type ProductCollectionFragment = {
  readonly __typename?: 'ProductCollection';
  readonly id?: number | null;
  readonly name?: string | null;
  readonly slug?: string | null;
};

export type ProductQueryErrorResultFragment = {
  readonly __typename?: 'ProductQueryErrorResult';
  readonly errors: {
    readonly __typename?: 'ProductQueryErrors';
    readonly store?: ReadonlyArray<string> | null;
    readonly listing?: ReadonlyArray<string> | null;
    readonly product?: ReadonlyArray<string> | null;
  };
};

export type ProductFragment = {
  readonly __typename?: 'Product';
  readonly currency?: Currency | null;
  readonly description?: string | null;
  readonly googleProductCategory?: string | null;
  readonly id?: string | null;
  readonly itemGroupId?: string | null;
  readonly listingId?: number | null;
  readonly primaryProductId?: number | null;
  readonly primaryProductSku?: string | null;
  readonly primaryProductType?: string | null;
  readonly checkout_flow?: number | null;
  readonly title?: string | null;
  readonly url?: string | null;
  readonly activeStoreSlug?: string | null;
  readonly images?: ReadonlyArray<{
    readonly __typename?: 'Image';
    readonly label?: string | null;
    readonly src?: string | null;
    readonly full?: string | null;
    readonly small?: string | null;
    readonly thumbLarge?: string | null;
    readonly thumbSmall?: string | null;
  }> | null;
  readonly listingThumbnails?: {
    readonly __typename?: 'ListingThumbnails';
    readonly primary?: string | null;
    readonly front?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
    readonly back?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
    readonly left?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
    readonly right?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
  } | null;
  readonly primaryProduct?: ReadonlyArray<{
    readonly __typename?: 'PrimaryProduct';
    readonly productType?: string | null;
    readonly productId?: number | null;
    readonly price?: string | null;
    readonly inventoryCount?: number | null;
    readonly currency?: Currency | null;
    readonly color?: string | null;
    readonly teespringId?: number | null;
    readonly variationId?: number | null;
    readonly productionEndDate?: string | null;
    readonly thumbnail?: {
      readonly __typename?: 'Thumbnails';
      readonly back?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
      readonly front?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
      readonly left?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
      readonly right?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
    } | null;
    readonly sizes?: ReadonlyArray<{
      readonly __typename?: 'Size';
      readonly id?: number | null;
      readonly label?: string | null;
      readonly price?: string | null;
    }> | null;
    readonly images?: ReadonlyArray<{
      readonly __typename?: 'Image';
      readonly label?: string | null;
      readonly src?: string | null;
      readonly full?: string | null;
      readonly small?: string | null;
      readonly thumbLarge?: string | null;
      readonly thumbSmall?: string | null;
    }> | null;
    readonly availableSizes?: ReadonlyArray<{
      readonly __typename?: 'Size';
      readonly id?: number | null;
      readonly label?: string | null;
      readonly price?: string | null;
    }> | null;
    readonly attributes?: { readonly __typename?: 'Attributes'; readonly hex?: string | null } | null;
  }> | null;
};

export type ProductsMetaFragment = {
  readonly __typename?: 'Products';
  readonly collection?: string | null;
  readonly count?: number | null;
  readonly total_count?: number | null;
  readonly per_page?: number | null;
  readonly page?: number | null;
  readonly next?: string | null;
};

export type ProductsQueryErrorResultFragment = {
  readonly __typename?: 'ProductsQueryErrorResult';
  readonly errors: { readonly __typename?: 'ProductsQueryErrors'; readonly store?: ReadonlyArray<string> | null };
};

export type ProductsFragment = {
  readonly __typename?: 'Products';
  readonly collection?: string | null;
  readonly count?: number | null;
  readonly total_count?: number | null;
  readonly per_page?: number | null;
  readonly page?: number | null;
  readonly next?: string | null;
  readonly products: ReadonlyArray<{
    readonly __typename?: 'Product';
    readonly currency?: Currency | null;
    readonly description?: string | null;
    readonly googleProductCategory?: string | null;
    readonly id?: string | null;
    readonly itemGroupId?: string | null;
    readonly listingId?: number | null;
    readonly primaryProductId?: number | null;
    readonly primaryProductSku?: string | null;
    readonly primaryProductType?: string | null;
    readonly checkout_flow?: number | null;
    readonly title?: string | null;
    readonly url?: string | null;
    readonly activeStoreSlug?: string | null;
    readonly images?: ReadonlyArray<{
      readonly __typename?: 'Image';
      readonly label?: string | null;
      readonly src?: string | null;
      readonly full?: string | null;
      readonly small?: string | null;
      readonly thumbLarge?: string | null;
      readonly thumbSmall?: string | null;
    }> | null;
    readonly listingThumbnails?: {
      readonly __typename?: 'ListingThumbnails';
      readonly primary?: string | null;
      readonly front?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
      readonly back?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
      readonly left?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
      readonly right?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
    } | null;
    readonly primaryProduct?: ReadonlyArray<{
      readonly __typename?: 'PrimaryProduct';
      readonly productType?: string | null;
      readonly productId?: number | null;
      readonly price?: string | null;
      readonly inventoryCount?: number | null;
      readonly currency?: Currency | null;
      readonly color?: string | null;
      readonly teespringId?: number | null;
      readonly variationId?: number | null;
      readonly productionEndDate?: string | null;
      readonly thumbnail?: {
        readonly __typename?: 'Thumbnails';
        readonly back?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
        readonly front?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
        readonly left?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
        readonly right?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
      } | null;
      readonly sizes?: ReadonlyArray<{
        readonly __typename?: 'Size';
        readonly id?: number | null;
        readonly label?: string | null;
        readonly price?: string | null;
      }> | null;
      readonly images?: ReadonlyArray<{
        readonly __typename?: 'Image';
        readonly label?: string | null;
        readonly src?: string | null;
        readonly full?: string | null;
        readonly small?: string | null;
        readonly thumbLarge?: string | null;
        readonly thumbSmall?: string | null;
      }> | null;
      readonly availableSizes?: ReadonlyArray<{
        readonly __typename?: 'Size';
        readonly id?: number | null;
        readonly label?: string | null;
        readonly price?: string | null;
      }> | null;
      readonly attributes?: { readonly __typename?: 'Attributes'; readonly hex?: string | null } | null;
    }> | null;
  }>;
};

export type SizeFragment = {
  readonly __typename?: 'Size';
  readonly id?: number | null;
  readonly label?: string | null;
  readonly price?: string | null;
};

export type ThumbnailsFragment = {
  readonly __typename?: 'Thumbnails';
  readonly back?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
  readonly front?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
  readonly left?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
  readonly right?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
};

export type StoreThemeAboutFragment = {
  readonly __typename?: 'About';
  readonly aboutText?: ReadonlyArray<{
    readonly __typename?: 'AboutText';
    readonly id?: string | null;
    readonly text?: string | null;
  }> | null;
};

export type StoreThemeBackgroundStyleFragment = {
  readonly __typename?: 'BackgroundStyle';
  readonly backgroundColor?: string | null;
};

export type StoreThemeBrandFragment = {
  readonly __typename?: 'Brand';
  readonly socialMedia?: ReadonlyArray<{
    readonly __typename?: 'SocialMedia';
    readonly icon?: string | null;
    readonly id?: string | null;
    readonly label?: string | null;
    readonly url?: string | null;
  }> | null;
};

export type StoreThemeButtonStorefrontFragment = {
  readonly __typename?: 'ButtonStorefront';
  readonly color?: string | null;
  readonly backgroundColor?: string | null;
  readonly borderColor?: string | null;
};

export type StoreThemeButtonFragment = {
  readonly __typename?: 'Button';
  readonly color?: string | null;
  readonly backgroundColor?: string | null;
  readonly borderColor?: string | null;
};

export type StoreThemeButtonsStorefrontFragment = {
  readonly __typename?: 'ButtonsStorefront';
  readonly primary?: {
    readonly __typename?: 'ButtonStorefront';
    readonly color?: string | null;
    readonly backgroundColor?: string | null;
    readonly borderColor?: string | null;
  } | null;
  readonly quickView?: {
    readonly __typename?: 'QuickViewButtonStorefront';
    readonly color?: string | null;
    readonly backgroundColor?: string | null;
    readonly borderColor?: string | null;
  } | null;
};

export type StoreThemeContentBlockStylesFragment = {
  readonly __typename?: 'ContentBlockStyles';
  readonly bgStyles?: { readonly __typename?: 'BackgroundStyle'; readonly backgroundColor?: string | null } | null;
  readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
};

export type StoreThemeContentFragment = {
  readonly __typename?: 'Content';
  readonly favicon?: string | null;
  readonly about?: {
    readonly __typename?: 'About';
    readonly aboutText?: ReadonlyArray<{
      readonly __typename?: 'AboutText';
      readonly id?: string | null;
      readonly text?: string | null;
    }> | null;
  } | null;
  readonly categories?: ReadonlyArray<{
    readonly __typename?: 'CustomCatergory';
    readonly image?: string | null;
    readonly displayName?: string | null;
    readonly slug?: string | null;
  }> | null;
  readonly footer?: {
    readonly __typename?: 'Footer';
    readonly newsletter?: boolean | null;
    readonly showSocialIcons?: boolean | null;
    readonly hidePoweredBy?: boolean | null;
    readonly about?: { readonly __typename?: 'FooterAbout'; readonly textContent?: string | null } | null;
  } | null;
  readonly header?: {
    readonly __typename?: 'Header';
    readonly hideSearch?: boolean | null;
    readonly logo?: string | null;
    readonly showAbout?: boolean | null;
    readonly showCategories?: boolean | null;
    readonly showSocialIcons?: boolean | null;
    readonly customLinks?: ReadonlyArray<{
      readonly __typename?: 'CustomLink';
      readonly name?: string | null;
      readonly slug?: string | null;
      readonly id?: string | null;
    }> | null;
  } | null;
  readonly heroBanner?: {
    readonly __typename?: 'HeroBanner';
    readonly body?: string | null;
    readonly containerBg?: string | null;
    readonly ctaLink?: string | null;
    readonly ctaOpenInNewWindow?: boolean | null;
    readonly ctaText?: string | null;
    readonly subtitle?: string | null;
    readonly title?: string | null;
  } | null;
};

export type StoreThemeCTAButtonStyleFragment = {
  readonly __typename?: 'CTAButtonStyle';
  readonly backgroundColor?: string | null;
  readonly color?: string | null;
};

export type StoreThemeCustomCategoryFragment = {
  readonly __typename?: 'CustomCatergory';
  readonly image?: string | null;
  readonly displayName?: string | null;
  readonly slug?: string | null;
};

export type StoreThemeCustomLinkFragment = {
  readonly __typename?: 'CustomLink';
  readonly name?: string | null;
  readonly slug?: string | null;
  readonly id?: string | null;
};

export type StoreThemeFontTypeFragment = {
  readonly __typename?: 'FontType';
  readonly letterSpacing?: string | null;
  readonly fontSize?: string | null;
  readonly fontWeight?: string | null;
  readonly textTransform?: string | null;
};

export type StoreThemeFooterAboutFragment = {
  readonly __typename?: 'FooterAbout';
  readonly textContent?: string | null;
};

export type StoreThemeFooterStylesFragment = {
  readonly __typename?: 'FooterStyles';
  readonly template?: number | null;
  readonly bgStyles?: { readonly __typename?: 'BackgroundStyle'; readonly backgroundColor?: string | null } | null;
  readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
};

export type StoreThemeFooterFragment = {
  readonly __typename?: 'Footer';
  readonly newsletter?: boolean | null;
  readonly showSocialIcons?: boolean | null;
  readonly hidePoweredBy?: boolean | null;
  readonly about?: { readonly __typename?: 'FooterAbout'; readonly textContent?: string | null } | null;
};

export type StoreThemeHeaderStylesFragment = {
  readonly __typename?: 'HeaderStyles';
  readonly template?: number | null;
  readonly bgStyles?: { readonly __typename?: 'BackgroundStyle'; readonly backgroundColor?: string | null } | null;
  readonly logo?: { readonly __typename?: 'LogoStyle'; readonly maxHeight?: string | null } | null;
  readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
};

export type StoreThemeHeaderFragment = {
  readonly __typename?: 'Header';
  readonly hideSearch?: boolean | null;
  readonly logo?: string | null;
  readonly showAbout?: boolean | null;
  readonly showCategories?: boolean | null;
  readonly showSocialIcons?: boolean | null;
  readonly customLinks?: ReadonlyArray<{
    readonly __typename?: 'CustomLink';
    readonly name?: string | null;
    readonly slug?: string | null;
    readonly id?: string | null;
  }> | null;
};

export type StoreThemeHeroBannerStylesFragment = {
  readonly __typename?: 'HeroBannerStyles';
  readonly template?: number | null;
  readonly bgStyles?: { readonly __typename?: 'BackgroundStyle'; readonly backgroundColor?: string | null } | null;
  readonly ctaBtnStyles?: {
    readonly __typename?: 'CTAButtonStyle';
    readonly backgroundColor?: string | null;
    readonly color?: string | null;
  } | null;
  readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
};

export type StoreThemeHeroBannerTypeFragment = {
  readonly __typename?: 'HeroBannerType';
  readonly type?: number | null;
};

export type StoreThemeHeroBannerFragment = {
  readonly __typename?: 'HeroBanner';
  readonly body?: string | null;
  readonly containerBg?: string | null;
  readonly ctaLink?: string | null;
  readonly ctaOpenInNewWindow?: boolean | null;
  readonly ctaText?: string | null;
  readonly subtitle?: string | null;
  readonly title?: string | null;
};

export type StoreThemeLayoutFragment = {
  readonly __typename?: 'Layout';
  readonly productList?: { readonly __typename?: 'ProductList'; readonly grid?: string | null } | null;
  readonly heroBanner?: { readonly __typename?: 'HeroBannerType'; readonly type?: number | null } | null;
};

export type StoreThemeLinkStylesFragment = {
  readonly __typename?: 'LinkStyles';
  readonly color?: string | null;
  readonly textDecoration?: string | null;
};

export type StoreThemeMetaFragment = {
  readonly __typename?: 'Meta';
  readonly logoHeight?: string | null;
  readonly mailchimpAudienceId?: string | null;
  readonly mailchimpUserId?: string | null;
  readonly primaryColor?: string | null;
  readonly storeId?: string | null;
  readonly storeName?: string | null;
  readonly storeUrl?: string | null;
  readonly searchOptions?: { readonly __typename?: 'SearchOptions'; readonly placeholder?: string | null } | null;
  readonly seoTitle?: {
    readonly __typename?: 'SeoTitle';
    readonly title?: string | null;
    readonly keywords?: string | null;
    readonly description?: string | null;
  } | null;
};

export type StoreThemeModalStyleFragment = {
  readonly __typename?: 'ModalStyle';
  readonly overlayColor?: string | null;
  readonly closeButtonColor?: string | null;
};

export type StoreThemeProductListFragment = { readonly __typename?: 'ProductList'; readonly grid?: string | null };

export type StoreThemeQuickViewButtonStorefrontFragment = {
  readonly __typename?: 'QuickViewButtonStorefront';
  readonly color?: string | null;
  readonly backgroundColor?: string | null;
  readonly borderColor?: string | null;
};

export type StoreThemeScopesFragment = {
  readonly __typename?: 'ThemeScopes';
  readonly storefront?: {
    readonly __typename?: 'ThemeStorefront';
    readonly styles?: {
      readonly __typename?: 'StylesStorefront';
      readonly buttons?: {
        readonly __typename?: 'ButtonsStorefront';
        readonly primary?: {
          readonly __typename?: 'ButtonStorefront';
          readonly color?: string | null;
          readonly backgroundColor?: string | null;
          readonly borderColor?: string | null;
        } | null;
        readonly quickView?: {
          readonly __typename?: 'QuickViewButtonStorefront';
          readonly color?: string | null;
          readonly backgroundColor?: string | null;
          readonly borderColor?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type StoreThemeSearchStyleFragment = {
  readonly __typename?: 'SearchStyle';
  readonly closeBtnStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
  readonly titleStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
  readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
  readonly bgStyles?: { readonly __typename?: 'BackgroundStyle'; readonly backgroundColor?: string | null } | null;
};

export type StoreThemeSeoTitleFragment = {
  readonly __typename?: 'SeoTitle';
  readonly title?: string | null;
  readonly keywords?: string | null;
  readonly description?: string | null;
};

export type StoreThemeSocialMediaFragment = {
  readonly __typename?: 'SocialMedia';
  readonly icon?: string | null;
  readonly id?: string | null;
  readonly label?: string | null;
  readonly url?: string | null;
};

export type StoreThemeStorefrontFragment = {
  readonly __typename?: 'ThemeStorefront';
  readonly styles?: {
    readonly __typename?: 'StylesStorefront';
    readonly buttons?: {
      readonly __typename?: 'ButtonsStorefront';
      readonly primary?: {
        readonly __typename?: 'ButtonStorefront';
        readonly color?: string | null;
        readonly backgroundColor?: string | null;
        readonly borderColor?: string | null;
      } | null;
      readonly quickView?: {
        readonly __typename?: 'QuickViewButtonStorefront';
        readonly color?: string | null;
        readonly backgroundColor?: string | null;
        readonly borderColor?: string | null;
      } | null;
    } | null;
  } | null;
};

export type StoreThemeStylesStorefrontFragment = {
  readonly __typename?: 'StylesStorefront';
  readonly buttons?: {
    readonly __typename?: 'ButtonsStorefront';
    readonly primary?: {
      readonly __typename?: 'ButtonStorefront';
      readonly color?: string | null;
      readonly backgroundColor?: string | null;
      readonly borderColor?: string | null;
    } | null;
    readonly quickView?: {
      readonly __typename?: 'QuickViewButtonStorefront';
      readonly color?: string | null;
      readonly backgroundColor?: string | null;
      readonly borderColor?: string | null;
    } | null;
  } | null;
};

export type StoreThemeStylesFragment = {
  readonly __typename?: 'Styles';
  readonly customCSS?: string | null;
  readonly fonts?: ReadonlyArray<string> | null;
  readonly primaryColor?: string | null;
  readonly button?: {
    readonly __typename?: 'Buttons';
    readonly primary?: {
      readonly __typename?: 'Button';
      readonly color?: string | null;
      readonly backgroundColor?: string | null;
      readonly borderColor?: string | null;
    } | null;
    readonly secondary?: {
      readonly __typename?: 'Button';
      readonly color?: string | null;
      readonly backgroundColor?: string | null;
      readonly borderColor?: string | null;
    } | null;
  } | null;
  readonly footer?: {
    readonly __typename?: 'FooterStyles';
    readonly template?: number | null;
    readonly bgStyles?: { readonly __typename?: 'BackgroundStyle'; readonly backgroundColor?: string | null } | null;
    readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
  } | null;
  readonly header?: {
    readonly __typename?: 'HeaderStyles';
    readonly template?: number | null;
    readonly bgStyles?: { readonly __typename?: 'BackgroundStyle'; readonly backgroundColor?: string | null } | null;
    readonly logo?: { readonly __typename?: 'LogoStyle'; readonly maxHeight?: string | null } | null;
    readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
  } | null;
  readonly heroBanner?: {
    readonly __typename?: 'HeroBannerStyles';
    readonly template?: number | null;
    readonly bgStyles?: { readonly __typename?: 'BackgroundStyle'; readonly backgroundColor?: string | null } | null;
    readonly ctaBtnStyles?: {
      readonly __typename?: 'CTAButtonStyle';
      readonly backgroundColor?: string | null;
      readonly color?: string | null;
    } | null;
    readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
  } | null;
  readonly linkStyles?: {
    readonly __typename?: 'LinkStyles';
    readonly color?: string | null;
    readonly textDecoration?: string | null;
  } | null;
  readonly modal?: {
    readonly __typename?: 'ModalStyle';
    readonly overlayColor?: string | null;
    readonly closeButtonColor?: string | null;
  } | null;
  readonly search?: {
    readonly __typename?: 'SearchStyle';
    readonly closeBtnStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
    readonly titleStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
    readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
    readonly bgStyles?: { readonly __typename?: 'BackgroundStyle'; readonly backgroundColor?: string | null } | null;
  } | null;
  readonly productList?: {
    readonly __typename?: 'ProductListStyles';
    readonly titleStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
  } | null;
  readonly about?: {
    readonly __typename?: 'ContentBlockStyles';
    readonly bgStyles?: { readonly __typename?: 'BackgroundStyle'; readonly backgroundColor?: string | null } | null;
    readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
  } | null;
  readonly listing?: {
    readonly __typename?: 'ContentBlockStyles';
    readonly bgStyles?: { readonly __typename?: 'BackgroundStyle'; readonly backgroundColor?: string | null } | null;
    readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
  } | null;
  readonly typography?: {
    readonly __typename?: 'TypographyStyles';
    readonly bodyFontFamily?: string | null;
    readonly headingFontFamily?: string | null;
    readonly h1?: {
      readonly __typename?: 'FontType';
      readonly letterSpacing?: string | null;
      readonly fontSize?: string | null;
      readonly fontWeight?: string | null;
      readonly textTransform?: string | null;
    } | null;
    readonly h2?: {
      readonly __typename?: 'FontType';
      readonly letterSpacing?: string | null;
      readonly fontSize?: string | null;
      readonly fontWeight?: string | null;
      readonly textTransform?: string | null;
    } | null;
    readonly h3?: {
      readonly __typename?: 'FontType';
      readonly letterSpacing?: string | null;
      readonly fontSize?: string | null;
      readonly fontWeight?: string | null;
      readonly textTransform?: string | null;
    } | null;
    readonly h4?: {
      readonly __typename?: 'FontType';
      readonly letterSpacing?: string | null;
      readonly fontSize?: string | null;
      readonly fontWeight?: string | null;
      readonly textTransform?: string | null;
    } | null;
    readonly h5?: {
      readonly __typename?: 'FontType';
      readonly letterSpacing?: string | null;
      readonly fontSize?: string | null;
      readonly fontWeight?: string | null;
      readonly textTransform?: string | null;
    } | null;
    readonly h6?: {
      readonly __typename?: 'FontType';
      readonly letterSpacing?: string | null;
      readonly fontSize?: string | null;
      readonly fontWeight?: string | null;
      readonly textTransform?: string | null;
    } | null;
  } | null;
};

export type StoreThemeTextStyleFragment = { readonly __typename?: 'TextStyle'; readonly color?: string | null };

export type StoreThemeTypographyStylesFragment = {
  readonly __typename?: 'TypographyStyles';
  readonly bodyFontFamily?: string | null;
  readonly headingFontFamily?: string | null;
  readonly h1?: {
    readonly __typename?: 'FontType';
    readonly letterSpacing?: string | null;
    readonly fontSize?: string | null;
    readonly fontWeight?: string | null;
    readonly textTransform?: string | null;
  } | null;
  readonly h2?: {
    readonly __typename?: 'FontType';
    readonly letterSpacing?: string | null;
    readonly fontSize?: string | null;
    readonly fontWeight?: string | null;
    readonly textTransform?: string | null;
  } | null;
  readonly h3?: {
    readonly __typename?: 'FontType';
    readonly letterSpacing?: string | null;
    readonly fontSize?: string | null;
    readonly fontWeight?: string | null;
    readonly textTransform?: string | null;
  } | null;
  readonly h4?: {
    readonly __typename?: 'FontType';
    readonly letterSpacing?: string | null;
    readonly fontSize?: string | null;
    readonly fontWeight?: string | null;
    readonly textTransform?: string | null;
  } | null;
  readonly h5?: {
    readonly __typename?: 'FontType';
    readonly letterSpacing?: string | null;
    readonly fontSize?: string | null;
    readonly fontWeight?: string | null;
    readonly textTransform?: string | null;
  } | null;
  readonly h6?: {
    readonly __typename?: 'FontType';
    readonly letterSpacing?: string | null;
    readonly fontSize?: string | null;
    readonly fontWeight?: string | null;
    readonly textTransform?: string | null;
  } | null;
};

export type StoreThemeFragment = {
  readonly __typename?: 'Theme';
  readonly storeKey?: string | null;
  readonly userId?: number | null;
  readonly content?: {
    readonly __typename?: 'Content';
    readonly favicon?: string | null;
    readonly about?: {
      readonly __typename?: 'About';
      readonly aboutText?: ReadonlyArray<{
        readonly __typename?: 'AboutText';
        readonly id?: string | null;
        readonly text?: string | null;
      }> | null;
    } | null;
    readonly categories?: ReadonlyArray<{
      readonly __typename?: 'CustomCatergory';
      readonly image?: string | null;
      readonly displayName?: string | null;
      readonly slug?: string | null;
    }> | null;
    readonly footer?: {
      readonly __typename?: 'Footer';
      readonly newsletter?: boolean | null;
      readonly showSocialIcons?: boolean | null;
      readonly hidePoweredBy?: boolean | null;
      readonly about?: { readonly __typename?: 'FooterAbout'; readonly textContent?: string | null } | null;
    } | null;
    readonly header?: {
      readonly __typename?: 'Header';
      readonly hideSearch?: boolean | null;
      readonly logo?: string | null;
      readonly showAbout?: boolean | null;
      readonly showCategories?: boolean | null;
      readonly showSocialIcons?: boolean | null;
      readonly customLinks?: ReadonlyArray<{
        readonly __typename?: 'CustomLink';
        readonly name?: string | null;
        readonly slug?: string | null;
        readonly id?: string | null;
      }> | null;
    } | null;
    readonly heroBanner?: {
      readonly __typename?: 'HeroBanner';
      readonly body?: string | null;
      readonly containerBg?: string | null;
      readonly ctaLink?: string | null;
      readonly ctaOpenInNewWindow?: boolean | null;
      readonly ctaText?: string | null;
      readonly subtitle?: string | null;
      readonly title?: string | null;
    } | null;
  } | null;
  readonly meta?: {
    readonly __typename?: 'Meta';
    readonly logoHeight?: string | null;
    readonly mailchimpAudienceId?: string | null;
    readonly mailchimpUserId?: string | null;
    readonly primaryColor?: string | null;
    readonly storeId?: string | null;
    readonly storeName?: string | null;
    readonly storeUrl?: string | null;
    readonly searchOptions?: { readonly __typename?: 'SearchOptions'; readonly placeholder?: string | null } | null;
    readonly seoTitle?: {
      readonly __typename?: 'SeoTitle';
      readonly title?: string | null;
      readonly keywords?: string | null;
      readonly description?: string | null;
    } | null;
  } | null;
  readonly brand?: {
    readonly __typename?: 'Brand';
    readonly socialMedia?: ReadonlyArray<{
      readonly __typename?: 'SocialMedia';
      readonly icon?: string | null;
      readonly id?: string | null;
      readonly label?: string | null;
      readonly url?: string | null;
    }> | null;
  } | null;
  readonly layout?: {
    readonly __typename?: 'Layout';
    readonly productList?: { readonly __typename?: 'ProductList'; readonly grid?: string | null } | null;
    readonly heroBanner?: { readonly __typename?: 'HeroBannerType'; readonly type?: number | null } | null;
  } | null;
  readonly styles?: {
    readonly __typename?: 'Styles';
    readonly customCSS?: string | null;
    readonly fonts?: ReadonlyArray<string> | null;
    readonly primaryColor?: string | null;
    readonly button?: {
      readonly __typename?: 'Buttons';
      readonly primary?: {
        readonly __typename?: 'Button';
        readonly color?: string | null;
        readonly backgroundColor?: string | null;
        readonly borderColor?: string | null;
      } | null;
      readonly secondary?: {
        readonly __typename?: 'Button';
        readonly color?: string | null;
        readonly backgroundColor?: string | null;
        readonly borderColor?: string | null;
      } | null;
    } | null;
    readonly footer?: {
      readonly __typename?: 'FooterStyles';
      readonly template?: number | null;
      readonly bgStyles?: { readonly __typename?: 'BackgroundStyle'; readonly backgroundColor?: string | null } | null;
      readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
    } | null;
    readonly header?: {
      readonly __typename?: 'HeaderStyles';
      readonly template?: number | null;
      readonly bgStyles?: { readonly __typename?: 'BackgroundStyle'; readonly backgroundColor?: string | null } | null;
      readonly logo?: { readonly __typename?: 'LogoStyle'; readonly maxHeight?: string | null } | null;
      readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
    } | null;
    readonly heroBanner?: {
      readonly __typename?: 'HeroBannerStyles';
      readonly template?: number | null;
      readonly bgStyles?: { readonly __typename?: 'BackgroundStyle'; readonly backgroundColor?: string | null } | null;
      readonly ctaBtnStyles?: {
        readonly __typename?: 'CTAButtonStyle';
        readonly backgroundColor?: string | null;
        readonly color?: string | null;
      } | null;
      readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
    } | null;
    readonly linkStyles?: {
      readonly __typename?: 'LinkStyles';
      readonly color?: string | null;
      readonly textDecoration?: string | null;
    } | null;
    readonly modal?: {
      readonly __typename?: 'ModalStyle';
      readonly overlayColor?: string | null;
      readonly closeButtonColor?: string | null;
    } | null;
    readonly search?: {
      readonly __typename?: 'SearchStyle';
      readonly closeBtnStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
      readonly titleStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
      readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
      readonly bgStyles?: { readonly __typename?: 'BackgroundStyle'; readonly backgroundColor?: string | null } | null;
    } | null;
    readonly productList?: {
      readonly __typename?: 'ProductListStyles';
      readonly titleStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
    } | null;
    readonly about?: {
      readonly __typename?: 'ContentBlockStyles';
      readonly bgStyles?: { readonly __typename?: 'BackgroundStyle'; readonly backgroundColor?: string | null } | null;
      readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
    } | null;
    readonly listing?: {
      readonly __typename?: 'ContentBlockStyles';
      readonly bgStyles?: { readonly __typename?: 'BackgroundStyle'; readonly backgroundColor?: string | null } | null;
      readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
    } | null;
    readonly typography?: {
      readonly __typename?: 'TypographyStyles';
      readonly bodyFontFamily?: string | null;
      readonly headingFontFamily?: string | null;
      readonly h1?: {
        readonly __typename?: 'FontType';
        readonly letterSpacing?: string | null;
        readonly fontSize?: string | null;
        readonly fontWeight?: string | null;
        readonly textTransform?: string | null;
      } | null;
      readonly h2?: {
        readonly __typename?: 'FontType';
        readonly letterSpacing?: string | null;
        readonly fontSize?: string | null;
        readonly fontWeight?: string | null;
        readonly textTransform?: string | null;
      } | null;
      readonly h3?: {
        readonly __typename?: 'FontType';
        readonly letterSpacing?: string | null;
        readonly fontSize?: string | null;
        readonly fontWeight?: string | null;
        readonly textTransform?: string | null;
      } | null;
      readonly h4?: {
        readonly __typename?: 'FontType';
        readonly letterSpacing?: string | null;
        readonly fontSize?: string | null;
        readonly fontWeight?: string | null;
        readonly textTransform?: string | null;
      } | null;
      readonly h5?: {
        readonly __typename?: 'FontType';
        readonly letterSpacing?: string | null;
        readonly fontSize?: string | null;
        readonly fontWeight?: string | null;
        readonly textTransform?: string | null;
      } | null;
      readonly h6?: {
        readonly __typename?: 'FontType';
        readonly letterSpacing?: string | null;
        readonly fontSize?: string | null;
        readonly fontWeight?: string | null;
        readonly textTransform?: string | null;
      } | null;
    } | null;
  } | null;
  readonly scopes?: {
    readonly __typename?: 'ThemeScopes';
    readonly storefront?: {
      readonly __typename?: 'ThemeStorefront';
      readonly styles?: {
        readonly __typename?: 'StylesStorefront';
        readonly buttons?: {
          readonly __typename?: 'ButtonsStorefront';
          readonly primary?: {
            readonly __typename?: 'ButtonStorefront';
            readonly color?: string | null;
            readonly backgroundColor?: string | null;
            readonly borderColor?: string | null;
          } | null;
          readonly quickView?: {
            readonly __typename?: 'QuickViewButtonStorefront';
            readonly color?: string | null;
            readonly backgroundColor?: string | null;
            readonly borderColor?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CreateCheckoutMutationVariables = Exact<{
  checkout: CheckoutDataInput;
}>;

export type CreateCheckoutMutation = {
  readonly createCheckout?:
    | {
        readonly __typename?: 'Checkout';
        readonly id?: string | null;
        readonly state?: CheckoutState | null;
        readonly deliveryOption?: CheckoutDeliveryOption | null;
        readonly orderLookupNumber?: string | null;
        readonly promoCode?: string | null;
        readonly lineItems?: ReadonlyArray<{
          readonly __typename?: 'LineItemDetail';
          readonly quantity?: number | null;
          readonly teespringID?: string | null;
          readonly buyerVariantId?: string | null;
          readonly productVariantId?: string | null;
          readonly shipping?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly discount?: {
            readonly __typename?: 'Discount';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly currency?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly tax?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly subtotal?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly unitPrice?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly fulfillmentDetails?: {
            readonly __typename?: 'FulfillmentDetails';
            readonly productionTechniques?: ReadonlyArray<string> | null;
          } | null;
        }> | null;
        readonly costs?: {
          readonly __typename?: 'Cost';
          readonly shipping?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly total?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly tax?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly discount?: {
            readonly __typename?: 'Discount';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly vat?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly subtotal?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly currency?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
        } | null;
      }
    | {
        readonly __typename?: 'CheckoutError';
        readonly reason: CheckoutErrorReason;
        readonly message?: string | null;
        readonly displayMessage?: string | null;
      }
    | null;
};

export type SubmitCheckoutMutationVariables = Exact<{
  checkout: SubmitCheckoutInput;
}>;

export type SubmitCheckoutMutation = {
  readonly submitCheckout?:
    | {
        readonly __typename?: 'Checkout';
        readonly id?: string | null;
        readonly state?: CheckoutState | null;
        readonly deliveryOption?: CheckoutDeliveryOption | null;
        readonly orderLookupNumber?: string | null;
        readonly promoCode?: string | null;
        readonly lineItems?: ReadonlyArray<{
          readonly __typename?: 'LineItemDetail';
          readonly quantity?: number | null;
          readonly teespringID?: string | null;
          readonly buyerVariantId?: string | null;
          readonly productVariantId?: string | null;
          readonly shipping?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly discount?: {
            readonly __typename?: 'Discount';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly currency?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly tax?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly subtotal?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly unitPrice?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly fulfillmentDetails?: {
            readonly __typename?: 'FulfillmentDetails';
            readonly productionTechniques?: ReadonlyArray<string> | null;
          } | null;
        }> | null;
        readonly costs?: {
          readonly __typename?: 'Cost';
          readonly shipping?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly total?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly tax?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly discount?: {
            readonly __typename?: 'Discount';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly vat?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly subtotal?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly currency?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
        } | null;
      }
    | {
        readonly __typename?: 'CheckoutError';
        readonly reason: CheckoutErrorReason;
        readonly message?: string | null;
        readonly displayMessage?: string | null;
      }
    | {
        readonly __typename?: 'CheckoutUnprocessableErrors';
        readonly errors: ReadonlyArray<{
          readonly __typename?: 'CheckoutUnprocessableError';
          readonly code: string;
          readonly source: { readonly __typename?: 'CheckoutUnprocessableErrorSource'; readonly pointer: string };
        }>;
      }
    | null;
};

export type UpdateCheckoutMutationVariables = Exact<{
  checkout: UpdateCheckoutInput;
}>;

export type UpdateCheckoutMutation = {
  readonly updateCheckout?:
    | {
        readonly __typename?: 'Checkout';
        readonly id?: string | null;
        readonly state?: CheckoutState | null;
        readonly deliveryOption?: CheckoutDeliveryOption | null;
        readonly orderLookupNumber?: string | null;
        readonly promoCode?: string | null;
        readonly lineItems?: ReadonlyArray<{
          readonly __typename?: 'LineItemDetail';
          readonly quantity?: number | null;
          readonly teespringID?: string | null;
          readonly buyerVariantId?: string | null;
          readonly productVariantId?: string | null;
          readonly shipping?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly discount?: {
            readonly __typename?: 'Discount';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly currency?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly tax?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly subtotal?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly unitPrice?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly fulfillmentDetails?: {
            readonly __typename?: 'FulfillmentDetails';
            readonly productionTechniques?: ReadonlyArray<string> | null;
          } | null;
        }> | null;
        readonly costs?: {
          readonly __typename?: 'Cost';
          readonly shipping?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly total?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly tax?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly discount?: {
            readonly __typename?: 'Discount';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly vat?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly subtotal?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly currency?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
        } | null;
      }
    | {
        readonly __typename?: 'CheckoutError';
        readonly reason: CheckoutErrorReason;
        readonly message?: string | null;
        readonly displayMessage?: string | null;
      }
    | {
        readonly __typename?: 'CheckoutUnprocessableErrors';
        readonly errors: ReadonlyArray<{
          readonly __typename?: 'CheckoutUnprocessableError';
          readonly code: string;
          readonly source: { readonly __typename?: 'CheckoutUnprocessableErrorSource'; readonly pointer: string };
        }>;
      }
    | null;
};

export type CreatePaymentIntentMutationVariables = Exact<{
  paymentIntentRequest: CreatePaymentIntentInput;
}>;

export type CreatePaymentIntentMutation = {
  readonly createPaymentIntent?:
    | {
        readonly __typename?: 'CreatePaymentIntentResult';
        readonly paymentId: string;
        readonly paymentToken: string;
        readonly clientSeceret: string;
      }
    | {
        readonly __typename?: 'PaymentIntentErrorResult';
        readonly reason: PaymentErrorReason;
        readonly context?: string | null;
      }
    | null;
};

export type InitiatePaymentMutationVariables = Exact<{
  paymentIntentRequest: InitiatePaymentIntentInput;
}>;

export type InitiatePaymentMutation = {
  readonly initiatePaymentIntent?:
    | { readonly __typename?: 'InitiatePaymentIntentResult'; readonly paymentId: string; readonly paymentToken: string }
    | {
        readonly __typename?: 'PaymentIntentErrorResult';
        readonly reason: PaymentErrorReason;
        readonly context?: string | null;
      }
    | null;
};

export type UpdatePaymentIntentMutationVariables = Exact<{
  paymentIntentRequest: UpdatePaymentIntentInput;
}>;

export type UpdatePaymentIntentMutation = {
  readonly updatePaymentIntent?:
    | {
        readonly __typename?: 'PaymentIntentErrorResult';
        readonly reason: PaymentErrorReason;
        readonly context?: string | null;
      }
    | { readonly __typename?: 'UpdatePaymentIntentResult'; readonly paymentId: string; readonly paymentToken: string }
    | null;
};

export type VerifyAddressQueryVariables = Exact<{
  address: AddressInput;
}>;

export type VerifyAddressQuery = {
  readonly verifyAddress?: {
    readonly __typename?: 'AddressVerificationResponse';
    readonly addressValid: boolean;
    readonly error?: string | null;
    readonly corrections?: ReadonlyArray<{
      readonly __typename?: 'AddressVerification';
      readonly delivery_line_1?: string | null;
      readonly last_line?: string | null;
      readonly components?: {
        readonly __typename?: 'AddressComponents';
        readonly primary_number?: string | null;
        readonly street_name?: string | null;
        readonly street_suffix?: string | null;
        readonly city_name?: string | null;
        readonly default_city_name?: string | null;
        readonly state_abbreviation?: string | null;
        readonly zipcode?: string | null;
        readonly plus4_code?: string | null;
      } | null;
    }> | null;
  } | null;
};

export type GetDeliveryOptionsQueryVariables = Exact<{
  options: GetDeliveryOptionsInput;
}>;

export type GetDeliveryOptionsQuery = {
  readonly getDeliveryOptions?:
    | {
        readonly __typename?: 'CheckoutError';
        readonly reason: CheckoutErrorReason;
        readonly message?: string | null;
        readonly displayMessage?: string | null;
      }
    | {
        readonly __typename?: 'DeliveryMinervaErrors';
        readonly errors?: ReadonlyArray<{
          readonly __typename?: 'DeliveryMinervaError';
          readonly title?: string | null;
          readonly status?: string | null;
          readonly detail?: string | null;
          readonly code?: string | null;
        }> | null;
      }
    | {
        readonly __typename?: 'DeliveryOptions';
        readonly attributes: {
          readonly __typename?: 'DeliveryOptionsAttributes';
          readonly items: ReadonlyArray<{
            readonly __typename?: 'DeliveryOptionsAttributesItem';
            readonly buyerVariant: string;
            readonly quantity?: number | null;
            readonly standardShippingCost?: {
              readonly __typename?: 'DeliveryOptionsCost';
              readonly currency: Currency;
              readonly amount: number;
            } | null;
          }>;
          readonly quotes: ReadonlyArray<{
            readonly __typename?: 'DeliveryOptionsAttributesQuote';
            readonly name?: DeliveryOption | null;
            readonly cost: {
              readonly __typename?: 'DeliveryOptionsCost';
              readonly currency: Currency;
              readonly amount: number;
            };
            readonly deliveryEstimates?: {
              readonly __typename?: 'DeliveryEstimates';
              readonly early: string;
              readonly late: string;
            } | null;
          }>;
          readonly shipments: ReadonlyArray<{
            readonly __typename?: 'DeliveryOptionsAttributesShipment';
            readonly items: ReadonlyArray<{
              readonly __typename?: 'DeliveryOptionsAttributesItem';
              readonly buyerVariant: string;
              readonly quantity?: number | null;
              readonly standardShippingCost?: {
                readonly __typename?: 'DeliveryOptionsCost';
                readonly currency: Currency;
                readonly amount: number;
              } | null;
            }>;
            readonly quotes?: ReadonlyArray<{
              readonly __typename?: 'DeliveryOptionsAttributesQuote';
              readonly name?: DeliveryOption | null;
              readonly cost: {
                readonly __typename?: 'DeliveryOptionsCost';
                readonly currency: Currency;
                readonly amount: number;
              };
              readonly deliveryEstimates?: {
                readonly __typename?: 'DeliveryEstimates';
                readonly early: string;
                readonly late: string;
              } | null;
            }> | null;
          }>;
        };
      }
    | null;
};

export type GetCheckoutQueryVariables = Exact<{
  storeSlug: Scalars['String']['input'];
  checkoutId: Scalars['String']['input'];
}>;

export type GetCheckoutQuery = {
  readonly getCheckout?:
    | {
        readonly __typename?: 'Checkout';
        readonly id?: string | null;
        readonly state?: CheckoutState | null;
        readonly deliveryOption?: CheckoutDeliveryOption | null;
        readonly orderLookupNumber?: string | null;
        readonly promoCode?: string | null;
        readonly lineItems?: ReadonlyArray<{
          readonly __typename?: 'LineItemDetail';
          readonly quantity?: number | null;
          readonly teespringID?: string | null;
          readonly buyerVariantId?: string | null;
          readonly productVariantId?: string | null;
          readonly shipping?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly discount?: {
            readonly __typename?: 'Discount';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly currency?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly tax?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly subtotal?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly unitPrice?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly fulfillmentDetails?: {
            readonly __typename?: 'FulfillmentDetails';
            readonly productionTechniques?: ReadonlyArray<string> | null;
          } | null;
        }> | null;
        readonly costs?: {
          readonly __typename?: 'Cost';
          readonly shipping?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly total?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly tax?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly discount?: {
            readonly __typename?: 'Discount';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly vat?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly subtotal?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
          readonly currency?: {
            readonly __typename?: 'Money';
            readonly currency?: Currency | null;
            readonly value?: number | null;
          } | null;
        } | null;
      }
    | {
        readonly __typename?: 'CheckoutError';
        readonly reason: CheckoutErrorReason;
        readonly message?: string | null;
        readonly displayMessage?: string | null;
      }
    | null;
};

export type StoreBasicProductsQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Float']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  items?: InputMaybe<Scalars['Float']['input']>;
  collection?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<ProductVisibilityInput>;
}>;

export type StoreBasicProductsQuery = {
  readonly products?:
    | {
        readonly __typename?: 'Products';
        readonly collection?: string | null;
        readonly count?: number | null;
        readonly total_count?: number | null;
        readonly per_page?: number | null;
        readonly page?: number | null;
        readonly next?: string | null;
        readonly basicProducts?: ReadonlyArray<{
          readonly __typename?: 'BasicProduct';
          readonly imageUrl?: string | null;
          readonly id?: number | null;
          readonly name?: string | null;
          readonly price?: string | null;
          readonly productGroupName?: string | null;
          readonly productName?: string | null;
          readonly url?: string | null;
          readonly listingSlug?: string | null;
          readonly orders?: number | null;
          readonly listingId?: number | null;
          readonly additionalImages?: ReadonlyArray<{
            readonly __typename?: 'AdditionalImage';
            readonly side?: string | null;
            readonly src?: string | null;
          }> | null;
          readonly collections?: ReadonlyArray<{
            readonly __typename?: 'ProductCollection';
            readonly id?: number | null;
            readonly name?: string | null;
            readonly slug?: string | null;
          }> | null;
        }> | null;
      }
    | {
        readonly __typename?: 'ProductsQueryErrorResult';
        readonly errors: { readonly __typename?: 'ProductsQueryErrors'; readonly store?: ReadonlyArray<string> | null };
      }
    | null;
};

export type StoreCollectionsV2QueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type StoreCollectionsV2Query = {
  readonly collectionsV2?:
    | {
        readonly __typename?: 'CollectionsQueryErrorResult';
        readonly errors: { readonly __typename?: 'CollectionsQueryErrors'; readonly store: ReadonlyArray<string> };
      }
    | {
        readonly __typename?: 'CollectionsV2';
        readonly storeId?: string | null;
        readonly storeSlug?: string | null;
        readonly collections?: ReadonlyArray<{
          readonly __typename?: 'Collection';
          readonly name?: string | null;
          readonly slug?: string | null;
          readonly collections?: ReadonlyArray<{
            readonly __typename?: 'CollectionItem';
            readonly name?: string | null;
            readonly slug?: string | null;
          }> | null;
        }> | null;
        readonly customCollections?: ReadonlyArray<{
          readonly __typename?: 'Collection';
          readonly name?: string | null;
          readonly slug?: string | null;
          readonly collections?: ReadonlyArray<{
            readonly __typename?: 'CollectionItem';
            readonly name?: string | null;
            readonly slug?: string | null;
          }> | null;
        }> | null;
      }
    | null;
};

export type StoreCollectionsQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type StoreCollectionsQuery = {
  readonly collections?:
    | {
        readonly __typename?: 'Collections';
        readonly storeId?: string | null;
        readonly storeSlug?: string | null;
        readonly collections?: ReadonlyArray<{
          readonly __typename?: 'Collection';
          readonly name?: string | null;
          readonly slug?: string | null;
          readonly collections?: ReadonlyArray<{
            readonly __typename?: 'CollectionItem';
            readonly name?: string | null;
            readonly slug?: string | null;
          }> | null;
        }> | null;
      }
    | {
        readonly __typename?: 'CollectionsQueryErrorResult';
        readonly errors: { readonly __typename?: 'CollectionsQueryErrors'; readonly store: ReadonlyArray<string> };
      }
    | null;
};

export type StoreDataQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type StoreDataQuery = {
  readonly store?:
    | {
        readonly __typename?: 'Store';
        readonly id?: string | null;
        readonly name?: string | null;
        readonly slug?: string | null;
        readonly description?: string | null;
        readonly faviconUrl?: string | null;
        readonly logoUrl?: string | null;
        readonly sellerId?: number | null;
        readonly sellerToken?: string | null;
        readonly logoWidth?: number | null;
        readonly logoHeight?: number | null;
        readonly useLogo?: boolean | null;
        readonly themeColor?: string | null;
        readonly navPrimary?: ReadonlyArray<string> | null;
        readonly bannerUrl?: string | null;
        readonly linkColor?: string | null;
        readonly storeErrors?: string | null;
        readonly socialIdentities?: { readonly __typename?: 'SocialIdentity'; readonly twitter?: string | null } | null;
        readonly marketingPixels?: {
          readonly __typename?: 'MarketingPixel';
          readonly googleAnalytics?: string | null;
          readonly googleAdwords?: string | null;
          readonly googleAdwordsLabel?: string | null;
          readonly twitter?: string | null;
          readonly pinterest?: string | null;
          readonly facebook?: string | null;
          readonly facebookCustomCategory?: string | null;
          readonly gmcMerchantId?: string | null;
        } | null;
        readonly promoCodes?: ReadonlyArray<
          | {
              readonly __typename?: 'PromoCodeFixedValue';
              readonly code: string;
              readonly type: DiscountType;
              readonly value: {
                readonly __typename?: 'PromoCodeValue';
                readonly amount?: number | null;
                readonly currency?: Currency | null;
              };
            }
          | { readonly __typename?: 'PromoCodeFreeShipping'; readonly code: string; readonly type: DiscountType }
          | {
              readonly __typename?: 'PromoCodePercentage';
              readonly code: string;
              readonly type: DiscountType;
              readonly percentage: number;
            }
        > | null;
      }
    | {
        readonly __typename?: 'StoreQueryErrorResult';
        readonly errors: { readonly __typename?: 'StoreQueryErrors'; readonly store: ReadonlyArray<string> };
      }
    | null;
};

export type StoreListingQueryVariables = Exact<{
  itemSlug: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  productId?: InputMaybe<Scalars['Float']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<ProductVisibilityInput>;
}>;

export type StoreListingQuery = {
  readonly product?:
    | {
        readonly __typename?: 'Product';
        readonly currency?: Currency | null;
        readonly description?: string | null;
        readonly googleProductCategory?: string | null;
        readonly id?: string | null;
        readonly itemGroupId?: string | null;
        readonly listingId?: number | null;
        readonly primaryProductId?: number | null;
        readonly primaryProductSku?: string | null;
        readonly primaryProductType?: string | null;
        readonly checkout_flow?: number | null;
        readonly title?: string | null;
        readonly url?: string | null;
        readonly activeStoreSlug?: string | null;
        readonly images?: ReadonlyArray<{
          readonly __typename?: 'Image';
          readonly label?: string | null;
          readonly src?: string | null;
          readonly full?: string | null;
          readonly small?: string | null;
          readonly thumbLarge?: string | null;
          readonly thumbSmall?: string | null;
        }> | null;
        readonly listingThumbnails?: {
          readonly __typename?: 'ListingThumbnails';
          readonly primary?: string | null;
          readonly front?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
          readonly back?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
          readonly left?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
          readonly right?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
        } | null;
        readonly primaryProduct?: ReadonlyArray<{
          readonly __typename?: 'PrimaryProduct';
          readonly productType?: string | null;
          readonly productId?: number | null;
          readonly price?: string | null;
          readonly inventoryCount?: number | null;
          readonly currency?: Currency | null;
          readonly color?: string | null;
          readonly teespringId?: number | null;
          readonly variationId?: number | null;
          readonly productionEndDate?: string | null;
          readonly thumbnail?: {
            readonly __typename?: 'Thumbnails';
            readonly back?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
            readonly front?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
            readonly left?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
            readonly right?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
          } | null;
          readonly sizes?: ReadonlyArray<{
            readonly __typename?: 'Size';
            readonly id?: number | null;
            readonly label?: string | null;
            readonly price?: string | null;
          }> | null;
          readonly images?: ReadonlyArray<{
            readonly __typename?: 'Image';
            readonly label?: string | null;
            readonly src?: string | null;
            readonly full?: string | null;
            readonly small?: string | null;
            readonly thumbLarge?: string | null;
            readonly thumbSmall?: string | null;
          }> | null;
          readonly availableSizes?: ReadonlyArray<{
            readonly __typename?: 'Size';
            readonly id?: number | null;
            readonly label?: string | null;
            readonly price?: string | null;
          }> | null;
          readonly attributes?: { readonly __typename?: 'Attributes'; readonly hex?: string | null } | null;
        }> | null;
      }
    | {
        readonly __typename?: 'ProductQueryErrorResult';
        readonly errors: {
          readonly __typename?: 'ProductQueryErrors';
          readonly store?: ReadonlyArray<string> | null;
          readonly listing?: ReadonlyArray<string> | null;
          readonly product?: ReadonlyArray<string> | null;
        };
      }
    | null;
};

export type ProductBlankQueryVariables = Exact<{
  productId?: InputMaybe<Scalars['Float']['input']>;
}>;

export type ProductBlankQuery = {
  readonly productBlank?:
    | {
        readonly __typename?: 'ProductBlank';
        readonly materials?: string | null;
        readonly sizeChartUrl?: string | null;
        readonly fit?: string | null;
        readonly description?: string | null;
        readonly sizeChart?: {
          readonly __typename?: 'SizeChart';
          readonly sizes?: ReadonlyArray<{
            readonly __typename?: 'SizeChartSize';
            readonly id?: string | null;
            readonly dimensions?: ReadonlyArray<{
              readonly __typename?: 'SizeChartDimension';
              readonly id?: string | null;
              readonly units?: ReadonlyArray<{
                readonly __typename?: 'SizeChartUnitValue';
                readonly id?: string | null;
                readonly value?: string | null;
              }> | null;
            }> | null;
          }> | null;
        } | null;
      }
    | {
        readonly __typename?: 'ProductBlankQueryErrorResult';
        readonly errors: {
          readonly __typename?: 'ProductBlankQueryErrors';
          readonly product?: ReadonlyArray<string> | null;
        };
      }
    | null;
};

export type StoreProductsQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Float']['input']>;
  items?: InputMaybe<Scalars['Float']['input']>;
  collection?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<ProductVisibilityInput>;
}>;

export type StoreProductsQuery = {
  readonly products?:
    | {
        readonly __typename?: 'Products';
        readonly collection?: string | null;
        readonly count?: number | null;
        readonly total_count?: number | null;
        readonly per_page?: number | null;
        readonly page?: number | null;
        readonly next?: string | null;
        readonly products: ReadonlyArray<{
          readonly __typename?: 'Product';
          readonly currency?: Currency | null;
          readonly description?: string | null;
          readonly googleProductCategory?: string | null;
          readonly id?: string | null;
          readonly itemGroupId?: string | null;
          readonly listingId?: number | null;
          readonly primaryProductId?: number | null;
          readonly primaryProductSku?: string | null;
          readonly primaryProductType?: string | null;
          readonly checkout_flow?: number | null;
          readonly title?: string | null;
          readonly url?: string | null;
          readonly activeStoreSlug?: string | null;
          readonly images?: ReadonlyArray<{
            readonly __typename?: 'Image';
            readonly label?: string | null;
            readonly src?: string | null;
            readonly full?: string | null;
            readonly small?: string | null;
            readonly thumbLarge?: string | null;
            readonly thumbSmall?: string | null;
          }> | null;
          readonly listingThumbnails?: {
            readonly __typename?: 'ListingThumbnails';
            readonly primary?: string | null;
            readonly front?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
            readonly back?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
            readonly left?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
            readonly right?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
          } | null;
          readonly primaryProduct?: ReadonlyArray<{
            readonly __typename?: 'PrimaryProduct';
            readonly productType?: string | null;
            readonly productId?: number | null;
            readonly price?: string | null;
            readonly inventoryCount?: number | null;
            readonly currency?: Currency | null;
            readonly color?: string | null;
            readonly teespringId?: number | null;
            readonly variationId?: number | null;
            readonly productionEndDate?: string | null;
            readonly thumbnail?: {
              readonly __typename?: 'Thumbnails';
              readonly back?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
              readonly front?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
              readonly left?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
              readonly right?: { readonly __typename?: 'ImgSrc'; readonly src?: string | null } | null;
            } | null;
            readonly sizes?: ReadonlyArray<{
              readonly __typename?: 'Size';
              readonly id?: number | null;
              readonly label?: string | null;
              readonly price?: string | null;
            }> | null;
            readonly images?: ReadonlyArray<{
              readonly __typename?: 'Image';
              readonly label?: string | null;
              readonly src?: string | null;
              readonly full?: string | null;
              readonly small?: string | null;
              readonly thumbLarge?: string | null;
              readonly thumbSmall?: string | null;
            }> | null;
            readonly availableSizes?: ReadonlyArray<{
              readonly __typename?: 'Size';
              readonly id?: number | null;
              readonly label?: string | null;
              readonly price?: string | null;
            }> | null;
            readonly attributes?: { readonly __typename?: 'Attributes'; readonly hex?: string | null } | null;
          }> | null;
        }>;
      }
    | {
        readonly __typename?: 'ProductsQueryErrorResult';
        readonly errors: { readonly __typename?: 'ProductsQueryErrors'; readonly store?: ReadonlyArray<string> | null };
      }
    | null;
};

export type StoreThemeQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type StoreThemeQuery = {
  readonly theme?: {
    readonly __typename?: 'Theme';
    readonly storeKey?: string | null;
    readonly userId?: number | null;
    readonly content?: {
      readonly __typename?: 'Content';
      readonly favicon?: string | null;
      readonly about?: {
        readonly __typename?: 'About';
        readonly aboutText?: ReadonlyArray<{
          readonly __typename?: 'AboutText';
          readonly id?: string | null;
          readonly text?: string | null;
        }> | null;
      } | null;
      readonly categories?: ReadonlyArray<{
        readonly __typename?: 'CustomCatergory';
        readonly image?: string | null;
        readonly displayName?: string | null;
        readonly slug?: string | null;
      }> | null;
      readonly footer?: {
        readonly __typename?: 'Footer';
        readonly newsletter?: boolean | null;
        readonly showSocialIcons?: boolean | null;
        readonly hidePoweredBy?: boolean | null;
        readonly about?: { readonly __typename?: 'FooterAbout'; readonly textContent?: string | null } | null;
      } | null;
      readonly header?: {
        readonly __typename?: 'Header';
        readonly hideSearch?: boolean | null;
        readonly logo?: string | null;
        readonly showAbout?: boolean | null;
        readonly showCategories?: boolean | null;
        readonly showSocialIcons?: boolean | null;
        readonly customLinks?: ReadonlyArray<{
          readonly __typename?: 'CustomLink';
          readonly name?: string | null;
          readonly slug?: string | null;
          readonly id?: string | null;
        }> | null;
      } | null;
      readonly heroBanner?: {
        readonly __typename?: 'HeroBanner';
        readonly body?: string | null;
        readonly containerBg?: string | null;
        readonly ctaLink?: string | null;
        readonly ctaOpenInNewWindow?: boolean | null;
        readonly ctaText?: string | null;
        readonly subtitle?: string | null;
        readonly title?: string | null;
      } | null;
    } | null;
    readonly meta?: {
      readonly __typename?: 'Meta';
      readonly logoHeight?: string | null;
      readonly mailchimpAudienceId?: string | null;
      readonly mailchimpUserId?: string | null;
      readonly primaryColor?: string | null;
      readonly storeId?: string | null;
      readonly storeName?: string | null;
      readonly storeUrl?: string | null;
      readonly searchOptions?: { readonly __typename?: 'SearchOptions'; readonly placeholder?: string | null } | null;
      readonly seoTitle?: {
        readonly __typename?: 'SeoTitle';
        readonly title?: string | null;
        readonly keywords?: string | null;
        readonly description?: string | null;
      } | null;
    } | null;
    readonly brand?: {
      readonly __typename?: 'Brand';
      readonly socialMedia?: ReadonlyArray<{
        readonly __typename?: 'SocialMedia';
        readonly icon?: string | null;
        readonly id?: string | null;
        readonly label?: string | null;
        readonly url?: string | null;
      }> | null;
    } | null;
    readonly layout?: {
      readonly __typename?: 'Layout';
      readonly productList?: { readonly __typename?: 'ProductList'; readonly grid?: string | null } | null;
      readonly heroBanner?: { readonly __typename?: 'HeroBannerType'; readonly type?: number | null } | null;
    } | null;
    readonly styles?: {
      readonly __typename?: 'Styles';
      readonly customCSS?: string | null;
      readonly fonts?: ReadonlyArray<string> | null;
      readonly primaryColor?: string | null;
      readonly button?: {
        readonly __typename?: 'Buttons';
        readonly primary?: {
          readonly __typename?: 'Button';
          readonly color?: string | null;
          readonly backgroundColor?: string | null;
          readonly borderColor?: string | null;
        } | null;
        readonly secondary?: {
          readonly __typename?: 'Button';
          readonly color?: string | null;
          readonly backgroundColor?: string | null;
          readonly borderColor?: string | null;
        } | null;
      } | null;
      readonly footer?: {
        readonly __typename?: 'FooterStyles';
        readonly template?: number | null;
        readonly bgStyles?: {
          readonly __typename?: 'BackgroundStyle';
          readonly backgroundColor?: string | null;
        } | null;
        readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
      } | null;
      readonly header?: {
        readonly __typename?: 'HeaderStyles';
        readonly template?: number | null;
        readonly bgStyles?: {
          readonly __typename?: 'BackgroundStyle';
          readonly backgroundColor?: string | null;
        } | null;
        readonly logo?: { readonly __typename?: 'LogoStyle'; readonly maxHeight?: string | null } | null;
        readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
      } | null;
      readonly heroBanner?: {
        readonly __typename?: 'HeroBannerStyles';
        readonly template?: number | null;
        readonly bgStyles?: {
          readonly __typename?: 'BackgroundStyle';
          readonly backgroundColor?: string | null;
        } | null;
        readonly ctaBtnStyles?: {
          readonly __typename?: 'CTAButtonStyle';
          readonly backgroundColor?: string | null;
          readonly color?: string | null;
        } | null;
        readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
      } | null;
      readonly linkStyles?: {
        readonly __typename?: 'LinkStyles';
        readonly color?: string | null;
        readonly textDecoration?: string | null;
      } | null;
      readonly modal?: {
        readonly __typename?: 'ModalStyle';
        readonly overlayColor?: string | null;
        readonly closeButtonColor?: string | null;
      } | null;
      readonly search?: {
        readonly __typename?: 'SearchStyle';
        readonly closeBtnStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
        readonly titleStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
        readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
        readonly bgStyles?: {
          readonly __typename?: 'BackgroundStyle';
          readonly backgroundColor?: string | null;
        } | null;
      } | null;
      readonly productList?: {
        readonly __typename?: 'ProductListStyles';
        readonly titleStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
      } | null;
      readonly about?: {
        readonly __typename?: 'ContentBlockStyles';
        readonly bgStyles?: {
          readonly __typename?: 'BackgroundStyle';
          readonly backgroundColor?: string | null;
        } | null;
        readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
      } | null;
      readonly listing?: {
        readonly __typename?: 'ContentBlockStyles';
        readonly bgStyles?: {
          readonly __typename?: 'BackgroundStyle';
          readonly backgroundColor?: string | null;
        } | null;
        readonly textStyles?: { readonly __typename?: 'TextStyle'; readonly color?: string | null } | null;
      } | null;
      readonly typography?: {
        readonly __typename?: 'TypographyStyles';
        readonly bodyFontFamily?: string | null;
        readonly headingFontFamily?: string | null;
        readonly h1?: {
          readonly __typename?: 'FontType';
          readonly letterSpacing?: string | null;
          readonly fontSize?: string | null;
          readonly fontWeight?: string | null;
          readonly textTransform?: string | null;
        } | null;
        readonly h2?: {
          readonly __typename?: 'FontType';
          readonly letterSpacing?: string | null;
          readonly fontSize?: string | null;
          readonly fontWeight?: string | null;
          readonly textTransform?: string | null;
        } | null;
        readonly h3?: {
          readonly __typename?: 'FontType';
          readonly letterSpacing?: string | null;
          readonly fontSize?: string | null;
          readonly fontWeight?: string | null;
          readonly textTransform?: string | null;
        } | null;
        readonly h4?: {
          readonly __typename?: 'FontType';
          readonly letterSpacing?: string | null;
          readonly fontSize?: string | null;
          readonly fontWeight?: string | null;
          readonly textTransform?: string | null;
        } | null;
        readonly h5?: {
          readonly __typename?: 'FontType';
          readonly letterSpacing?: string | null;
          readonly fontSize?: string | null;
          readonly fontWeight?: string | null;
          readonly textTransform?: string | null;
        } | null;
        readonly h6?: {
          readonly __typename?: 'FontType';
          readonly letterSpacing?: string | null;
          readonly fontSize?: string | null;
          readonly fontWeight?: string | null;
          readonly textTransform?: string | null;
        } | null;
      } | null;
    } | null;
    readonly scopes?: {
      readonly __typename?: 'ThemeScopes';
      readonly storefront?: {
        readonly __typename?: 'ThemeStorefront';
        readonly styles?: {
          readonly __typename?: 'StylesStorefront';
          readonly buttons?: {
            readonly __typename?: 'ButtonsStorefront';
            readonly primary?: {
              readonly __typename?: 'ButtonStorefront';
              readonly color?: string | null;
              readonly backgroundColor?: string | null;
              readonly borderColor?: string | null;
            } | null;
            readonly quickView?: {
              readonly __typename?: 'QuickViewButtonStorefront';
              readonly color?: string | null;
              readonly backgroundColor?: string | null;
              readonly borderColor?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export const CreatePaymentIntentResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreatePaymentIntentResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatePaymentIntentResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'paymentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentToken' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clientSeceret' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePaymentIntentResultFragment, unknown>;
export const PaymentIntentErrorResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentIntentErrorResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentIntentErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaymentIntentErrorResultFragment, unknown>;
export const CreatePaymentIntentResponseFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreatePaymentIntentResponseFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatePaymentIntentResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatePaymentIntentResult' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CreatePaymentIntentResultFragment' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentIntentErrorResult' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentIntentErrorResultFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreatePaymentIntentResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatePaymentIntentResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'paymentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentToken' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clientSeceret' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentIntentErrorResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentIntentErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePaymentIntentResponseFragment, unknown>;
export const InitiatePaymentIntentResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InitiatePaymentIntentResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InitiatePaymentIntentResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'paymentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentToken' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InitiatePaymentIntentResultFragment, unknown>;
export const InitiatePaymentIntentResponseFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InitiatePaymentIntentResponseFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InitiatePaymentIntentResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InitiatePaymentIntentResult' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InitiatePaymentIntentResultFragment' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentIntentErrorResult' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentIntentErrorResultFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InitiatePaymentIntentResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InitiatePaymentIntentResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'paymentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentToken' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentIntentErrorResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentIntentErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InitiatePaymentIntentResponseFragment, unknown>;
export const UpdatePaymentIntentResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpdatePaymentIntentResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdatePaymentIntentResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'paymentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentToken' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePaymentIntentResultFragment, unknown>;
export const UpdatePaymentIntentResponseFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpdatePaymentIntentResponseFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdatePaymentIntentResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdatePaymentIntentResult' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UpdatePaymentIntentResultFragment' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentIntentErrorResult' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentIntentErrorResultFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpdatePaymentIntentResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdatePaymentIntentResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'paymentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentToken' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentIntentErrorResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentIntentErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePaymentIntentResponseFragment, unknown>;
export const ProductBlankQueryErrorsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductBlankQueryErrorsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductBlankQueryErrors' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'product' } }] },
    },
  ],
} as unknown as DocumentNode<ProductBlankQueryErrorsFragment, unknown>;
export const ProductBlankQueryErrorResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductBlankQueryErrorResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductBlankQueryErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductBlankQueryErrorsFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductBlankQueryErrorsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductBlankQueryErrors' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'product' } }] },
    },
  ],
} as unknown as DocumentNode<ProductBlankQueryErrorResultFragment, unknown>;
export const SizeChartSizeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SizeChartSizeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SizeChartSize' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dimensions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'units' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SizeChartSizeFragment, unknown>;
export const SizeChartFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SizeChartFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SizeChart' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sizes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SizeChartSizeFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SizeChartSizeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SizeChartSize' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dimensions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'units' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SizeChartFragment, unknown>;
export const ProductBlankFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductBlankFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductBlank' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'materials' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sizeChart' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SizeChartFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeChartUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SizeChartSizeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SizeChartSize' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dimensions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'units' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SizeChartFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SizeChart' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sizes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SizeChartSizeFragment' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductBlankFragment, unknown>;
export const AddressVerificationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressVerificationFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AddressVerificationResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addressValid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'corrections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'delivery_line_1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_line' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'components' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'primary_number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street_suffix' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'default_city_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state_abbreviation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plus4_code' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'error' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddressVerificationFragment, unknown>;
export const CheckoutErrorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckoutErrorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayMessage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutErrorFragment, unknown>;
export const CheckoutUnprocessableErrorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckoutUnprocessableErrorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutUnprocessableError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'source' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'pointer' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutUnprocessableErrorFragment, unknown>;
export const CheckoutUnprocessableErrorsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckoutUnprocessableErrorsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutUnprocessableErrors' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CheckoutUnprocessableErrorFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckoutUnprocessableErrorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutUnprocessableError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'source' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'pointer' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutUnprocessableErrorsFragment, unknown>;
export const CostsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Cost' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipping' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tax' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vat' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtotal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CostsFragment, unknown>;
export const CheckoutFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckoutFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Checkout' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryOption' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lineItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subtotal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unitPrice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teespringID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buyerVariantId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productVariantId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fulfillmentDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'productionTechniques' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostsFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'orderLookupNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Cost' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipping' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tax' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vat' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtotal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutFragment, unknown>;
export const DeliveryMinervaErrorsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DeliveryMinervaErrorsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryMinervaErrors' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeliveryMinervaErrorsFragment, unknown>;
export const DeliveryOptionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DeliveryOptionsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryOptions' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'buyerVariant' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'standardShippingCost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quotes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deliveryEstimates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'early' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'late' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'buyerVariant' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'standardShippingCost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quotes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'deliveryEstimates' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'early' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'late' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeliveryOptionsFragment, unknown>;
export const CollectionsQueryErrorResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionsQueryErrorResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionsQueryErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'store' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollectionsQueryErrorResultFragment, unknown>;
export const CollectionItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionItemFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollectionItemFragment, unknown>;
export const CollectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Collection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CollectionItemFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionItemFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollectionFragment, unknown>;
export const CollectionsV2FragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionsV2Fragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionsV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeSlug' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CollectionFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customCollections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CollectionFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionItemFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Collection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CollectionItemFragment' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollectionsV2Fragment, unknown>;
export const CollectionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Collections' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeSlug' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CollectionFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionItemFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Collection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CollectionItemFragment' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollectionsFragment, unknown>;
export const StorePromoCodePercentageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StorePromoCodePercentageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PromoCodePercentage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', alias: { kind: 'Name', value: 'percentage' }, name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StorePromoCodePercentageFragment, unknown>;
export const StorePromoCodeFreeShippingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StorePromoCodeFreeShippingFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PromoCodeFreeShipping' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StorePromoCodeFreeShippingFragment, unknown>;
export const StorePromoCodeFixedValueFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StorePromoCodeFixedValueFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PromoCodeFixedValue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'value' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StorePromoCodeFixedValueFragment, unknown>;
export const StoreDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreDataFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Store' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'faviconUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialIdentities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'twitter' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sellerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellerToken' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketingPixels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'googleAnalytics' } },
                { kind: 'Field', name: { kind: 'Name', value: 'googleAdwords' } },
                { kind: 'Field', name: { kind: 'Name', value: 'googleAdwordsLabel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'twitter' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pinterest' } },
                { kind: 'Field', name: { kind: 'Name', value: 'facebook' } },
                { kind: 'Field', name: { kind: 'Name', value: 'facebookCustomCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmcMerchantId' } },
              ],
            },
          },
          { kind: 'Field', alias: { kind: 'Name', value: 'storeErrors' }, name: { kind: 'Name', value: 'errors' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promoCodes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PromoCodePercentage' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StorePromoCodePercentageFragment' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PromoCodeFreeShipping' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StorePromoCodeFreeShippingFragment' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PromoCodeFixedValue' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StorePromoCodeFixedValueFragment' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'logoWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useLogo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'themeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'navPrimary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bannerUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkColor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StorePromoCodePercentageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PromoCodePercentage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', alias: { kind: 'Name', value: 'percentage' }, name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StorePromoCodeFreeShippingFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PromoCodeFreeShipping' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StorePromoCodeFixedValueFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PromoCodeFixedValue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'value' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreDataFragment, unknown>;
export const StoreQueryErrorResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreQueryErrorResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StoreQueryErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'store' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreQueryErrorResultFragment, unknown>;
export const ProductsMetaFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductsMetaFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Products' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'collection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'per_page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'next' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductsMetaFragment, unknown>;
export const AdditionalImageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdditionalImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdditionalImage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'side' } },
          { kind: 'Field', name: { kind: 'Name', value: 'src' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdditionalImageFragment, unknown>;
export const ProductCollectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductCollectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductCollectionFragment, unknown>;
export const BasicProductFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicProductFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicProduct' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'additionalImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AdditionalImageFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productGroupName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listingSlug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orders' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductCollectionFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'listingId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdditionalImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdditionalImage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'side' } },
          { kind: 'Field', name: { kind: 'Name', value: 'src' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductCollectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BasicProductFragment, unknown>;
export const BasicProductsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicProductsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Products' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductsMetaFragment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basicProducts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicProductFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdditionalImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdditionalImage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'side' } },
          { kind: 'Field', name: { kind: 'Name', value: 'src' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductCollectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductsMetaFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Products' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'collection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'per_page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'next' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicProductFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicProduct' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'additionalImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AdditionalImageFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productGroupName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listingSlug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orders' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductCollectionFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'listingId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BasicProductsFragment, unknown>;
export const ProductQueryErrorResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductQueryErrorResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductQueryErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'store' } },
                { kind: 'Field', name: { kind: 'Name', value: 'listing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductQueryErrorResultFragment, unknown>;
export const ProductsQueryErrorResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductsQueryErrorResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductsQueryErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'store' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductsQueryErrorResultFragment, unknown>;
export const ImageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Image' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'src' } },
          { kind: 'Field', name: { kind: 'Name', value: 'full' } },
          { kind: 'Field', name: { kind: 'Name', value: 'small' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbLarge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbSmall' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImageFragment, unknown>;
export const ListingThumbnailsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingThumbnailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ListingThumbnails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'front' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'back' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'left' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'right' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingThumbnailsFragment, unknown>;
export const ThumbnailsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThumbnailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Thumbnails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'back' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'front' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'left' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'right' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ThumbnailsFragment, unknown>;
export const SizeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SizeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Size' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SizeFragment, unknown>;
export const PrimaryProductFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrimaryProductFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PrimaryProduct' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThumbnailsFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sizes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SizeFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inventoryCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availableSizes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SizeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hex' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'teespringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productionEndDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThumbnailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Thumbnails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'back' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'front' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'left' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'right' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SizeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Size' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Image' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'src' } },
          { kind: 'Field', name: { kind: 'Name', value: 'full' } },
          { kind: 'Field', name: { kind: 'Name', value: 'small' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbLarge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbSmall' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrimaryProductFragment, unknown>;
export const ProductFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'googleProductCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listingId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingThumbnails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ListingThumbnailsFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryProduct' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PrimaryProductFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryProductId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryProductSku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryProductType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkout_flow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeStoreSlug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThumbnailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Thumbnails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'back' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'front' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'left' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'right' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SizeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Size' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Image' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'src' } },
          { kind: 'Field', name: { kind: 'Name', value: 'full' } },
          { kind: 'Field', name: { kind: 'Name', value: 'small' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbLarge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbSmall' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingThumbnailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ListingThumbnails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'front' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'back' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'left' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'right' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrimaryProductFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PrimaryProduct' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThumbnailsFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sizes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SizeFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inventoryCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availableSizes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SizeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hex' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'teespringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productionEndDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductFragment, unknown>;
export const ProductsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Products' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductsMetaFragment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'countryCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'countryCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Image' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'src' } },
          { kind: 'Field', name: { kind: 'Name', value: 'full' } },
          { kind: 'Field', name: { kind: 'Name', value: 'small' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbLarge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbSmall' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingThumbnailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ListingThumbnails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'front' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'back' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'left' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'right' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThumbnailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Thumbnails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'back' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'front' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'left' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'right' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SizeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Size' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrimaryProductFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PrimaryProduct' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThumbnailsFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sizes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SizeFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inventoryCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availableSizes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SizeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hex' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'teespringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productionEndDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductsMetaFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Products' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'collection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'per_page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'next' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'googleProductCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listingId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingThumbnails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ListingThumbnailsFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryProduct' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PrimaryProductFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryProductId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryProductSku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryProductType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkout_flow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeStoreSlug' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductsFragment, unknown>;
export const StoreThemeAboutFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeAboutFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'About' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'aboutText' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeAboutFragment, unknown>;
export const StoreThemeCustomCategoryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeCustomCategoryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomCatergory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeCustomCategoryFragment, unknown>;
export const StoreThemeFooterAboutFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeFooterAboutFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FooterAbout' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'textContent' } }],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeFooterAboutFragment, unknown>;
export const StoreThemeFooterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeFooterFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Footer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'about' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFooterAboutFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'newsletter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showSocialIcons' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hidePoweredBy' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeFooterAboutFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FooterAbout' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'textContent' } }],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeFooterFragment, unknown>;
export const StoreThemeCustomLinkFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeCustomLinkFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeCustomLinkFragment, unknown>;
export const StoreThemeHeaderFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeHeaderFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Header' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeCustomLinkFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hideSearch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showAbout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showCategories' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showSocialIcons' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeCustomLinkFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeHeaderFragment, unknown>;
export const StoreThemeHeroBannerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeHeroBannerFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeroBanner' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'containerBg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaOpenInNewWindow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeHeroBannerFragment, unknown>;
export const StoreThemeContentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeContentFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Content' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'about' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeAboutFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeCustomCategoryFragment' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'favicon' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'footer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFooterFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'header' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeHeaderFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heroBanner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeHeroBannerFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeFooterAboutFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FooterAbout' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'textContent' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeCustomLinkFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeAboutFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'About' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'aboutText' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeCustomCategoryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomCatergory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeFooterFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Footer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'about' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFooterAboutFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'newsletter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showSocialIcons' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hidePoweredBy' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeHeaderFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Header' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeCustomLinkFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hideSearch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showAbout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showCategories' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showSocialIcons' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeHeroBannerFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeroBanner' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'containerBg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaOpenInNewWindow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeContentFragment, unknown>;
export const StoreThemeSeoTitleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeSeoTitleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoTitle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeSeoTitleFragment, unknown>;
export const StoreThemeMetaFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeMetaFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Meta' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'logoHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailchimpAudienceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailchimpUserId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'placeholder' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seoTitle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeSeoTitleFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeSeoTitleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoTitle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeMetaFragment, unknown>;
export const StoreThemeSocialMediaFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeSocialMediaFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SocialMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeSocialMediaFragment, unknown>;
export const StoreThemeBrandFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeBrandFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Brand' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialMedia' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeSocialMediaFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeSocialMediaFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SocialMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeBrandFragment, unknown>;
export const StoreThemeProductListFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeProductListFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductList' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'grid' } }] },
    },
  ],
} as unknown as DocumentNode<StoreThemeProductListFragment, unknown>;
export const StoreThemeHeroBannerTypeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeHeroBannerTypeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeroBannerType' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }] },
    },
  ],
} as unknown as DocumentNode<StoreThemeHeroBannerTypeFragment, unknown>;
export const StoreThemeLayoutFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeLayoutFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Layout' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeProductListFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heroBanner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeHeroBannerTypeFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeProductListFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductList' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'grid' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeHeroBannerTypeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeroBannerType' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }] },
    },
  ],
} as unknown as DocumentNode<StoreThemeLayoutFragment, unknown>;
export const StoreThemeButtonFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeButtonFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Button' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'borderColor' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeButtonFragment, unknown>;
export const StoreThemeFooterStylesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeFooterStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FooterStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'template' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'color' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeFooterStylesFragment, unknown>;
export const StoreThemeHeaderStylesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeHeaderStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeaderStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'template' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'color' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeHeaderStylesFragment, unknown>;
export const StoreThemeBackgroundStyleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeBackgroundStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BackgroundStyle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } }],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeBackgroundStyleFragment, unknown>;
export const StoreThemeCTAButtonStyleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeCTAButtonStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CTAButtonStyle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeCTAButtonStyleFragment, unknown>;
export const StoreThemeTextStyleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TextStyle' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'color' } }] },
    },
  ],
} as unknown as DocumentNode<StoreThemeTextStyleFragment, unknown>;
export const StoreThemeHeroBannerStylesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeHeroBannerStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeroBannerStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeBackgroundStyleFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ctaBtnStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeCTAButtonStyleFragment' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'template' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeBackgroundStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BackgroundStyle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeCTAButtonStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CTAButtonStyle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TextStyle' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'color' } }] },
    },
  ],
} as unknown as DocumentNode<StoreThemeHeroBannerStylesFragment, unknown>;
export const StoreThemeLinkStylesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeLinkStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LinkStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textDecoration' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeLinkStylesFragment, unknown>;
export const StoreThemeModalStyleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeModalStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModalStyle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'overlayColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closeButtonColor' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeModalStyleFragment, unknown>;
export const StoreThemeSearchStyleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeSearchStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchStyle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'closeBtnStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'titleStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeBackgroundStyleFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TextStyle' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'color' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeBackgroundStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BackgroundStyle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } }],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeSearchStyleFragment, unknown>;
export const StoreThemeContentBlockStylesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeContentBlockStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ContentBlockStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeBackgroundStyleFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeBackgroundStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BackgroundStyle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TextStyle' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'color' } }] },
    },
  ],
} as unknown as DocumentNode<StoreThemeContentBlockStylesFragment, unknown>;
export const StoreThemeFontTypeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FontType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'letterSpacing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontWeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textTransform' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeFontTypeFragment, unknown>;
export const StoreThemeTypographyStylesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeTypographyStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TypographyStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bodyFontFamily' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headingFontFamily' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h4' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h5' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h6' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FontType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'letterSpacing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontWeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textTransform' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeTypographyStylesFragment, unknown>;
export const StoreThemeStylesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Styles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'button' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'primary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeButtonFragment' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'secondary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeButtonFragment' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'customCSS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fonts' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'footer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFooterStylesFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'header' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeHeaderStylesFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heroBanner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeHeroBannerStylesFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeLinkStylesFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeModalStyleFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeSearchStyleFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'titleStyles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'about' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeContentBlockStylesFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeContentBlockStylesFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typography' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTypographyStylesFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeBackgroundStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BackgroundStyle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeCTAButtonStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CTAButtonStyle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TextStyle' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'color' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FontType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'letterSpacing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontWeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textTransform' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeButtonFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Button' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'borderColor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeFooterStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FooterStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'template' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'color' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeHeaderStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeaderStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'template' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'color' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeHeroBannerStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeroBannerStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeBackgroundStyleFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ctaBtnStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeCTAButtonStyleFragment' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'template' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeLinkStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LinkStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textDecoration' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeModalStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModalStyle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'overlayColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closeButtonColor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeSearchStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchStyle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'closeBtnStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'titleStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeBackgroundStyleFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeContentBlockStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ContentBlockStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeBackgroundStyleFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeTypographyStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TypographyStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bodyFontFamily' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headingFontFamily' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h4' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h5' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h6' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeStylesFragment, unknown>;
export const StoreThemeButtonStorefrontFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeButtonStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ButtonStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'borderColor' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeButtonStorefrontFragment, unknown>;
export const StoreThemeQuickViewButtonStorefrontFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeQuickViewButtonStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'QuickViewButtonStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'borderColor' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeQuickViewButtonStorefrontFragment, unknown>;
export const StoreThemeButtonsStorefrontFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeButtonsStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ButtonsStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeButtonStorefrontFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quickView' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StoreThemeQuickViewButtonStorefrontFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeButtonStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ButtonStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'borderColor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeQuickViewButtonStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'QuickViewButtonStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'borderColor' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeButtonsStorefrontFragment, unknown>;
export const StoreThemeStylesStorefrontFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeStylesStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StylesStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeButtonsStorefrontFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeButtonStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ButtonStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'borderColor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeQuickViewButtonStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'QuickViewButtonStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'borderColor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeButtonsStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ButtonsStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeButtonStorefrontFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quickView' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StoreThemeQuickViewButtonStorefrontFragment' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeStylesStorefrontFragment, unknown>;
export const StoreThemeStorefrontFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ThemeStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'styles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeStylesStorefrontFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeButtonStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ButtonStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'borderColor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeQuickViewButtonStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'QuickViewButtonStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'borderColor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeButtonsStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ButtonsStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeButtonStorefrontFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quickView' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StoreThemeQuickViewButtonStorefrontFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeStylesStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StylesStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeButtonsStorefrontFragment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeStorefrontFragment, unknown>;
export const StoreThemeScopesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeScopesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ThemeScopes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeStorefrontFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeButtonStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ButtonStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'borderColor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeQuickViewButtonStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'QuickViewButtonStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'borderColor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeButtonsStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ButtonsStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeButtonStorefrontFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quickView' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StoreThemeQuickViewButtonStorefrontFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeStylesStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StylesStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeButtonsStorefrontFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ThemeStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'styles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeStylesStorefrontFragment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeScopesFragment, unknown>;
export const StoreThemeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Theme' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'content' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeContentFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'storeKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeMetaFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeBrandFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'layout' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeLayoutFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'styles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeStylesFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scopes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeScopesFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeAboutFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'About' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'aboutText' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeCustomCategoryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomCatergory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeFooterAboutFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FooterAbout' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'textContent' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeFooterFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Footer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'about' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFooterAboutFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'newsletter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showSocialIcons' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hidePoweredBy' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeCustomLinkFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeHeaderFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Header' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeCustomLinkFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hideSearch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showAbout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showCategories' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showSocialIcons' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeHeroBannerFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeroBanner' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'containerBg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaOpenInNewWindow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeSeoTitleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoTitle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeSocialMediaFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SocialMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeProductListFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductList' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'grid' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeHeroBannerTypeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeroBannerType' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeButtonFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Button' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'borderColor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeFooterStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FooterStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'template' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'color' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeHeaderStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeaderStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'template' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'color' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeBackgroundStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BackgroundStyle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeCTAButtonStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CTAButtonStyle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TextStyle' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'color' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeHeroBannerStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeroBannerStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeBackgroundStyleFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ctaBtnStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeCTAButtonStyleFragment' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'template' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeLinkStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LinkStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textDecoration' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeModalStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModalStyle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'overlayColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closeButtonColor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeSearchStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchStyle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'closeBtnStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'titleStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeBackgroundStyleFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeContentBlockStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ContentBlockStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeBackgroundStyleFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FontType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'letterSpacing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontWeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textTransform' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeTypographyStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TypographyStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bodyFontFamily' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headingFontFamily' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h4' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h5' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h6' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeButtonStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ButtonStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'borderColor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeQuickViewButtonStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'QuickViewButtonStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'borderColor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeButtonsStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ButtonsStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeButtonStorefrontFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quickView' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StoreThemeQuickViewButtonStorefrontFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeStylesStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StylesStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeButtonsStorefrontFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ThemeStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'styles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeStylesStorefrontFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeContentFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Content' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'about' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeAboutFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeCustomCategoryFragment' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'favicon' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'footer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFooterFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'header' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeHeaderFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heroBanner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeHeroBannerFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeMetaFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Meta' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'logoHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailchimpAudienceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailchimpUserId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'placeholder' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seoTitle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeSeoTitleFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeBrandFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Brand' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialMedia' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeSocialMediaFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeLayoutFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Layout' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeProductListFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heroBanner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeHeroBannerTypeFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Styles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'button' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'primary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeButtonFragment' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'secondary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeButtonFragment' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'customCSS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fonts' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'footer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFooterStylesFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'header' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeHeaderStylesFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heroBanner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeHeroBannerStylesFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeLinkStylesFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeModalStyleFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeSearchStyleFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'titleStyles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'about' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeContentBlockStylesFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeContentBlockStylesFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typography' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTypographyStylesFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeScopesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ThemeScopes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeStorefrontFragment' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeFragment, unknown>;
export const CreateCheckoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCheckout' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checkout' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutDataInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCheckout' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'checkout' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'checkout' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Checkout' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CheckoutFragment' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutError' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CheckoutErrorFragment' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Cost' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipping' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tax' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vat' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtotal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckoutFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Checkout' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryOption' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lineItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subtotal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unitPrice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teespringID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buyerVariantId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productVariantId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fulfillmentDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'productionTechniques' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostsFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'orderLookupNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckoutErrorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayMessage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCheckoutMutation, CreateCheckoutMutationVariables>;
export const SubmitCheckoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitCheckout' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checkout' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SubmitCheckoutInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitCheckout' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'checkout' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'checkout' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Checkout' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CheckoutFragment' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutError' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CheckoutErrorFragment' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutUnprocessableErrors' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CheckoutUnprocessableErrorsFragment' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Cost' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipping' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tax' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vat' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtotal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckoutUnprocessableErrorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutUnprocessableError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'source' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'pointer' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckoutFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Checkout' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryOption' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lineItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subtotal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unitPrice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teespringID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buyerVariantId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productVariantId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fulfillmentDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'productionTechniques' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostsFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'orderLookupNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckoutErrorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayMessage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckoutUnprocessableErrorsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutUnprocessableErrors' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CheckoutUnprocessableErrorFragment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmitCheckoutMutation, SubmitCheckoutMutationVariables>;
export const UpdateCheckoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCheckout' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checkout' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateCheckoutInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCheckout' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'checkout' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'checkout' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Checkout' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CheckoutFragment' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutError' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CheckoutErrorFragment' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutUnprocessableErrors' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CheckoutUnprocessableErrorsFragment' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Cost' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipping' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tax' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vat' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtotal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckoutUnprocessableErrorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutUnprocessableError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'source' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'pointer' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckoutFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Checkout' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryOption' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lineItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subtotal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unitPrice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teespringID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buyerVariantId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productVariantId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fulfillmentDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'productionTechniques' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostsFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'orderLookupNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckoutErrorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayMessage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckoutUnprocessableErrorsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutUnprocessableErrors' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CheckoutUnprocessableErrorFragment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCheckoutMutation, UpdateCheckoutMutationVariables>;
export const CreatePaymentIntentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePaymentIntent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentIntentRequest' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatePaymentIntentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPaymentIntent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentIntentRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentIntentRequest' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatePaymentIntentResponse' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CreatePaymentIntentResponseFragment' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreatePaymentIntentResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatePaymentIntentResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'paymentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentToken' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clientSeceret' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentIntentErrorResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentIntentErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreatePaymentIntentResponseFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatePaymentIntentResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatePaymentIntentResult' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CreatePaymentIntentResultFragment' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentIntentErrorResult' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentIntentErrorResultFragment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>;
export const InitiatePaymentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InitiatePayment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentIntentRequest' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InitiatePaymentIntentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initiatePaymentIntent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentIntentRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentIntentRequest' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InitiatePaymentIntentResponse' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'InitiatePaymentIntentResponseFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InitiatePaymentIntentResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InitiatePaymentIntentResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'paymentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentToken' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentIntentErrorResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentIntentErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InitiatePaymentIntentResponseFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InitiatePaymentIntentResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InitiatePaymentIntentResult' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InitiatePaymentIntentResultFragment' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentIntentErrorResult' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentIntentErrorResultFragment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InitiatePaymentMutation, InitiatePaymentMutationVariables>;
export const UpdatePaymentIntentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePaymentIntent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentIntentRequest' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdatePaymentIntentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePaymentIntent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentIntentRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentIntentRequest' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdatePaymentIntentResponse' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UpdatePaymentIntentResponseFragment' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpdatePaymentIntentResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdatePaymentIntentResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'paymentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentToken' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentIntentErrorResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentIntentErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpdatePaymentIntentResponseFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdatePaymentIntentResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdatePaymentIntentResult' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UpdatePaymentIntentResultFragment' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentIntentErrorResult' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentIntentErrorResultFragment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePaymentIntentMutation, UpdatePaymentIntentMutationVariables>;
export const VerifyAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VerifyAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddressInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyAddress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'address' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressVerificationFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressVerificationFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AddressVerificationResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addressValid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'corrections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'delivery_line_1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_line' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'components' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'primary_number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street_suffix' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'default_city_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state_abbreviation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plus4_code' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'error' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyAddressQuery, VerifyAddressQueryVariables>;
export const GetDeliveryOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDeliveryOptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'options' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetDeliveryOptionsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDeliveryOptions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'options' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'options' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryOptions' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DeliveryOptionsFragment' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutError' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CheckoutErrorFragment' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryMinervaErrors' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DeliveryMinervaErrorsFragment' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DeliveryOptionsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryOptions' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'buyerVariant' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'standardShippingCost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quotes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deliveryEstimates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'early' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'late' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'buyerVariant' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'standardShippingCost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quotes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'deliveryEstimates' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'early' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'late' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckoutErrorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayMessage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DeliveryMinervaErrorsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryMinervaErrors' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDeliveryOptionsQuery, GetDeliveryOptionsQueryVariables>;
export const GetCheckoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCheckout' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeSlug' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checkoutId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCheckout' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storeSlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeSlug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'checkoutId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'checkoutId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Checkout' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CheckoutFragment' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutError' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CheckoutErrorFragment' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CostsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Cost' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipping' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tax' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vat' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subtotal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckoutFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Checkout' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryOption' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lineItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subtotal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unitPrice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teespringID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buyerVariantId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productVariantId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fulfillmentDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'productionTechniques' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'costs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CostsFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'orderLookupNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckoutErrorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayMessage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCheckoutQuery, GetCheckoutQueryVariables>;
export const StoreBasicProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StoreBasicProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'region' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'items' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'collection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'visibility' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductVisibilityInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'region' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'region' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'items' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'items' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'collection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'collection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'visibility' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'visibility' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicProductsFragment' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductsQueryErrorResultFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductsMetaFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Products' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'collection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'per_page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'next' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdditionalImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AdditionalImage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'side' } },
          { kind: 'Field', name: { kind: 'Name', value: 'src' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductCollectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicProductFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicProduct' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'additionalImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AdditionalImageFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productGroupName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listingSlug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orders' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductCollectionFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'listingId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicProductsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Products' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductsMetaFragment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basicProducts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicProductFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductsQueryErrorResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductsQueryErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'store' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreBasicProductsQuery, StoreBasicProductsQueryVariables>;
export const StoreCollectionsV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StoreCollectionsV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collectionsV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CollectionsV2Fragment' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CollectionsQueryErrorResultFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionItemFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Collection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CollectionItemFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionsV2Fragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionsV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeSlug' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CollectionFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customCollections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CollectionFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionsQueryErrorResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionsQueryErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'store' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreCollectionsV2Query, StoreCollectionsV2QueryVariables>;
export const StoreCollectionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StoreCollections' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CollectionsFragment' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CollectionsQueryErrorResultFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionItemFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Collection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CollectionItemFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Collections' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeSlug' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CollectionFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CollectionsQueryErrorResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CollectionsQueryErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'store' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreCollectionsQuery, StoreCollectionsQueryVariables>;
export const StoreDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StoreData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreDataFragment' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreQueryErrorResultFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StorePromoCodePercentageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PromoCodePercentage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', alias: { kind: 'Name', value: 'percentage' }, name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StorePromoCodeFreeShippingFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PromoCodeFreeShipping' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StorePromoCodeFixedValueFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PromoCodeFixedValue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'value' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreDataFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Store' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'faviconUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialIdentities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'twitter' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sellerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellerToken' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketingPixels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'googleAnalytics' } },
                { kind: 'Field', name: { kind: 'Name', value: 'googleAdwords' } },
                { kind: 'Field', name: { kind: 'Name', value: 'googleAdwordsLabel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'twitter' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pinterest' } },
                { kind: 'Field', name: { kind: 'Name', value: 'facebook' } },
                { kind: 'Field', name: { kind: 'Name', value: 'facebookCustomCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmcMerchantId' } },
              ],
            },
          },
          { kind: 'Field', alias: { kind: 'Name', value: 'storeErrors' }, name: { kind: 'Name', value: 'errors' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'promoCodes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PromoCodePercentage' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StorePromoCodePercentageFragment' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PromoCodeFreeShipping' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StorePromoCodeFreeShippingFragment' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PromoCodeFixedValue' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StorePromoCodeFixedValueFragment' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'logoWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useLogo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'themeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'navPrimary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bannerUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkColor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreQueryErrorResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StoreQueryErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'store' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreDataQuery, StoreDataQueryVariables>;
export const StoreListingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StoreListing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'itemSlug' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'region' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'countryCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'visibility' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductVisibilityInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'itemSlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'itemSlug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'region' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'region' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'countryCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'countryCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'visibility' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'visibility' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductFragment' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductQueryErrorResultFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Image' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'src' } },
          { kind: 'Field', name: { kind: 'Name', value: 'full' } },
          { kind: 'Field', name: { kind: 'Name', value: 'small' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbLarge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbSmall' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingThumbnailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ListingThumbnails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'front' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'back' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'left' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'right' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThumbnailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Thumbnails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'back' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'front' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'left' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'right' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SizeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Size' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrimaryProductFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PrimaryProduct' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThumbnailsFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sizes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SizeFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inventoryCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availableSizes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SizeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hex' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'teespringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productionEndDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'googleProductCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listingId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingThumbnails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ListingThumbnailsFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryProduct' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PrimaryProductFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryProductId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryProductSku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryProductType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkout_flow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeStoreSlug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductQueryErrorResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductQueryErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'store' } },
                { kind: 'Field', name: { kind: 'Name', value: 'listing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreListingQuery, StoreListingQueryVariables>;
export const ProductBlankDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProductBlank' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productBlank' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductBlankFragment' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductBlankQueryErrorResultFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SizeChartSizeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SizeChartSize' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dimensions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'units' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SizeChartFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SizeChart' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sizes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SizeChartSizeFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductBlankQueryErrorsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductBlankQueryErrors' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'product' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductBlankFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductBlank' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'materials' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sizeChart' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SizeChartFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeChartUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductBlankQueryErrorResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductBlankQueryErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductBlankQueryErrorsFragment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductBlankQuery, ProductBlankQueryVariables>;
export const StoreProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StoreProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'items' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'collection' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'region' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'countryCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'visibility' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductVisibilityInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'region' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'region' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'items' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'items' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'collection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'collection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'visibility' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'visibility' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductsFragment' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductsQueryErrorResultFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductsMetaFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Products' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'collection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'per_page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'next' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Image' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'src' } },
          { kind: 'Field', name: { kind: 'Name', value: 'full' } },
          { kind: 'Field', name: { kind: 'Name', value: 'small' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbLarge' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbSmall' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingThumbnailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ListingThumbnails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'primary' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'front' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'back' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'left' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'right' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThumbnailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Thumbnails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'back' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'front' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'left' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'right' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'src' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SizeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Size' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrimaryProductFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PrimaryProduct' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThumbnailsFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sizes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SizeFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inventoryCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availableSizes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SizeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hex' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'teespringId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productionEndDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'googleProductCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'itemGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listingId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingThumbnails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ListingThumbnailsFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primaryProduct' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PrimaryProductFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryProductId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryProductSku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryProductType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkout_flow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeStoreSlug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Products' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductsMetaFragment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'countryCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'countryCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductsQueryErrorResultFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductsQueryErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'store' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreProductsQuery, StoreProductsQueryVariables>;
export const StoreThemeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StoreTheme' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'theme' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeAboutFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'About' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'aboutText' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeCustomCategoryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomCatergory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeFooterAboutFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FooterAbout' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'textContent' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeFooterFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Footer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'about' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFooterAboutFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'newsletter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showSocialIcons' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hidePoweredBy' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeCustomLinkFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeHeaderFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Header' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeCustomLinkFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hideSearch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showAbout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showCategories' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showSocialIcons' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeHeroBannerFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeroBanner' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'containerBg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaOpenInNewWindow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeContentFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Content' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'about' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeAboutFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeCustomCategoryFragment' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'favicon' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'footer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFooterFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'header' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeHeaderFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heroBanner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeHeroBannerFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeSeoTitleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoTitle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeMetaFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Meta' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'logoHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailchimpAudienceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailchimpUserId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'placeholder' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seoTitle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeSeoTitleFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeSocialMediaFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SocialMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeBrandFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Brand' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialMedia' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeSocialMediaFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeProductListFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductList' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'grid' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeHeroBannerTypeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeroBannerType' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeLayoutFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Layout' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeProductListFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heroBanner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeHeroBannerTypeFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeButtonFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Button' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'borderColor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeFooterStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FooterStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'template' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'color' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeHeaderStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeaderStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'template' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'color' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeBackgroundStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BackgroundStyle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } }],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeCTAButtonStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CTAButtonStyle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TextStyle' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'color' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeHeroBannerStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeroBannerStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeBackgroundStyleFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ctaBtnStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeCTAButtonStyleFragment' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'template' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeLinkStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LinkStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textDecoration' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeModalStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ModalStyle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'overlayColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closeButtonColor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeSearchStyleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchStyle' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'closeBtnStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'titleStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeBackgroundStyleFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeContentBlockStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ContentBlockStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bgStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeBackgroundStyleFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'textStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FontType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'letterSpacing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontWeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'textTransform' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeTypographyStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TypographyStyles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bodyFontFamily' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headingFontFamily' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h4' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h5' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'h6' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFontTypeFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeStylesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Styles' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'button' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'primary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeButtonFragment' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'secondary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeButtonFragment' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'customCSS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fonts' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'footer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeFooterStylesFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'header' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeHeaderStylesFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'heroBanner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeHeroBannerStylesFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkStyles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeLinkStylesFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeModalStyleFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeSearchStyleFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'titleStyles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTextStyleFragment' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'about' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeContentBlockStylesFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeContentBlockStylesFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'typography' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeTypographyStylesFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeButtonStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ButtonStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'borderColor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeQuickViewButtonStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'QuickViewButtonStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'borderColor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeButtonsStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ButtonsStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'primary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeButtonStorefrontFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quickView' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StoreThemeQuickViewButtonStorefrontFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeStylesStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StylesStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeButtonsStorefrontFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeStorefrontFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ThemeStorefront' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'styles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeStylesStorefrontFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeScopesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ThemeScopes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storefront' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeStorefrontFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreThemeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Theme' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'content' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeContentFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'storeKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeMetaFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeBrandFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'layout' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeLayoutFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'styles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeStylesFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scopes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreThemeScopesFragment' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreThemeQuery, StoreThemeQueryVariables>;
