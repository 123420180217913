/* eslint-disable no-underscore-dangle */
import {
  CheckoutDeliveryOption,
  CheckoutErrorFragment,
  CheckoutFragment,
  CheckoutState,
  CheckoutUnprocessableErrorsFragment,
  CollectionsV2Fragment,
  CollectionsQueryErrorResultFragment,
  Currency,
  DeliveryMinervaErrorsFragment,
  DeliveryOption,
  DiscountType,
  PaymentIntentErrorResultFragment,
  ProductFragment,
  ProductQueryErrorResultFragment,
  ProductsFragment,
  ProductsQueryErrorResultFragment,
  StoreDataFragment,
  StorePromoCodeFixedValueFragment,
  StorePromoCodeFreeShippingFragment,
  StorePromoCodePercentageFragment,
  StoreQueryErrorResultFragment,
  ProductBlankQueryErrorResultFragment,
} from '@/gql';
import { safeUnreachable } from '@/lib/tsutils';

/**
 * Type guard for CheckoutDeliveryOption
 *
 * @param candidate The maybe a CheckoutDeliveryOption to test
 * @returns true if the candidate appears to be a CheckoutDeliveryOption
 */
export function isCheckoutDeliveryOption(candidate: any): candidate is CheckoutDeliveryOption {
  const checkoutDeliveryOption: CheckoutDeliveryOption = candidate as CheckoutDeliveryOption;

  switch (checkoutDeliveryOption) {
    case 'fullRushCost':
    case 'fullStandardCost':
    case 'fullSuperRushCost':
      return true;
    default:
      // This wont compile if the above case statements aren't exhaustive for CheckoutDeliveryOption, but
      // will return false if reached (and candidate isn't a CheckoutDeliveryOption)
      return safeUnreachable(checkoutDeliveryOption, false);
  }
}

/**
 * Type guard for CheckoutState
 *
 * @param candidate The maybe a CheckoutState to test
 * @returns true if the candidate appears to be a CheckoutState
 */
export function isCheckoutState(candidate: any): candidate is CheckoutState {
  const checkoutState: CheckoutState = candidate as CheckoutState;

  switch (checkoutState) {
    case 'completed':
    case 'created':
    case 'failure':
    case 'pending':
    case 'success':
      return true;
    default:
      // This wont compile if the above case statements aren't exhaustive for CheckoutState, but
      // will return false if reached (and candidate isn't a CheckoutState)
      return safeUnreachable(checkoutState, false);
  }
}

/**
 * Type guard for Currency
 *
 * @param candidate The maybe a Currency to test
 * @returns true if the candidate appears to be a Currency
 */
export function isCurrency(candidate: any): candidate is Currency {
  const currency: Currency = candidate as Currency;

  switch (currency) {
    case 'AUD':
    case 'CAD':
    case 'EUR':
    case 'GBP':
    case 'USD':
      return true;
    default:
      // This wont compile if the above case statements aren't exhaustive for Currency, but
      // will return false if reached (and candidate isn't a Currency)
      return safeUnreachable(currency, false);
  }
}

/**
 * Type guard for DeliveryOption
 *
 * @param candidate The maybe a DeliveryOption to test
 * @returns true if the candidate appears to be a DeliveryOption
 */
export function isDeliveryOption(candidate: any): candidate is DeliveryOption {
  const deliveryOption: DeliveryOption = candidate as DeliveryOption;

  switch (deliveryOption) {
    case 'standard':
    case 'rush':
    case 'super_rush':
      return true;
    default:
      // This wont compile if the above case statements aren't exhaustive for DeliveryOption, but
      // will return false if reached (and candidate isn't a DeliveryOption)
      return safeUnreachable(deliveryOption, false);
  }
}

/**
 * Type guard for DiscountType
 *
 * @param candidate The maybe a DiscountType to test
 * @returns true if the candidate appears to be a DiscountType
 */
export function isDiscountType(candidate: any): candidate is DiscountType {
  const discountType: DiscountType = candidate as DiscountType;

  switch (discountType) {
    case 'fixed_value':
    case 'free_shipping':
    case 'percentage':
      return true;
    default:
      // This wont compile if the above case statements aren't exhaustive for DiscountType, but
      // will return false if reached (and candidate isn't a DiscountType)
      return safeUnreachable(discountType, false);
  }
}

/**
 * Simple type guard for ProductFragment
 *
 * @param candidate The maybe a ProductFragment to test
 * @returns true if the candidate appears to be a ProductFragment
 */
export function isProductFragment(candidate: any): candidate is ProductFragment {
  const productFragment: ProductFragment = candidate as ProductFragment;
  // eslint-disable-next-line no-underscore-dangle
  return Boolean(productFragment && typeof productFragment === 'object' && productFragment.__typename === 'Product');
}

/**
 * Simple type guard for ProductsFragment
 *
 * @param candidate The maybe a ProductsFragment to test
 * @returns true if the candidate appears to be a ProductsFragment
 */
export function isProductsFragment(candidate: any): candidate is ProductsFragment {
  const productsFragment: ProductsFragment = candidate as ProductsFragment;
  // eslint-disable-next-line no-underscore-dangle
  return Boolean(
    productsFragment && typeof productsFragment === 'object' && productsFragment.__typename === 'Products'
  );
}

/**
 * Simple type guard for ProductsQueryErrorResultFragment
 *
 * @param candidate The maybe a ProductsQueryErrorResultFragment to test
 * @returns true if the candidate appears to be a ProductsQueryErrorResultFragment
 */
export function isProductsQueryErrorResultFragment(candidate: any): candidate is ProductsQueryErrorResultFragment {
  const productsQueryErrorResultFragment: ProductsQueryErrorResultFragment =
    candidate as ProductsQueryErrorResultFragment;
  // eslint-disable-next-line no-underscore-dangle
  return Boolean(
    productsQueryErrorResultFragment &&
      typeof productsQueryErrorResultFragment === 'object' &&
      productsQueryErrorResultFragment.__typename === 'ProductsQueryErrorResult'
  );
}

/**
 * Simple type guard for ProductQueryErrorResultFragment
 *
 * @param candidate The maybe a ProductQueryErrorResultFragment to test
 * @returns true if the candidate appears to be a ProductQueryErrorResultFragment
 */
export function isProductQueryErrorResultFragment(candidate: any): candidate is ProductQueryErrorResultFragment {
  const productQueryErrorResultFragment: ProductQueryErrorResultFragment = candidate as ProductQueryErrorResultFragment;
  // eslint-disable-next-line no-underscore-dangle
  return Boolean(
    productQueryErrorResultFragment &&
      typeof productQueryErrorResultFragment === 'object' &&
      productQueryErrorResultFragment.__typename === 'ProductQueryErrorResult'
  );
}

/**
 * Simple type guard for StoreDataFragment
 *
 * @param candidate The maybe a StoreDataFragment to test
 * @returns true if the candidate appears to be a StoreDataFragment
 */
export function isStoreDataFragment(candidate: any): candidate is StoreDataFragment {
  const storeDataFragment: StoreDataFragment = candidate as StoreDataFragment;
  // eslint-disable-next-line no-underscore-dangle
  return Boolean(
    storeDataFragment && typeof storeDataFragment === 'object' && storeDataFragment.__typename === 'Store'
  );
}

/**
 * Simple type guard for StoreQueryErrorResultFragment
 *
 * @param candidate The maybe a StoreQueryErrorResultFragment to test
 * @returns true if the candidate appears to be a StoreQueryErrorResultFragment
 */
export function isStoreQueryErrorResultFragment(candidate: any): candidate is StoreQueryErrorResultFragment {
  const storeQueryErrorResultFragment: StoreQueryErrorResultFragment = candidate as StoreQueryErrorResultFragment;
  // eslint-disable-next-line no-underscore-dangle
  return Boolean(
    storeQueryErrorResultFragment &&
      typeof storeQueryErrorResultFragment === 'object' &&
      storeQueryErrorResultFragment.__typename === 'StoreQueryErrorResult'
  );
}

/**
 * Simple type guard for StorePromoCodePercentageFragment
 *
 * @param candidate The maybe a StorePromoCodePercentageFragment to test
 * @returns true if the candidate appears to be a StorePromoCodePercentageFragment
 */
export function isStorePromoCodePercentageFragment(candidate: any): candidate is StorePromoCodePercentageFragment {
  const storePromoCodePercentageFragment: StorePromoCodePercentageFragment =
    candidate as StorePromoCodePercentageFragment;
  // eslint-disable-next-line no-underscore-dangle
  return Boolean(
    storePromoCodePercentageFragment &&
      typeof storePromoCodePercentageFragment === 'object' &&
      storePromoCodePercentageFragment.__typename === 'PromoCodePercentage'
  );
}

/**
 * Simple type guard for StorePromoCodeFreeShippingFragment
 *
 * @param candidate The maybe a StorePromoCodeFreeShippingFragment to test
 * @returns true if the candidate appears to be a StorePromoCodeFreeShippingFragment
 */
export function isStorePromoCodeFreeShippingFragment(candidate: any): candidate is StorePromoCodeFreeShippingFragment {
  const storePromoCodeFreeShippingFragment: StorePromoCodeFreeShippingFragment =
    candidate as StorePromoCodeFreeShippingFragment;
  // eslint-disable-next-line no-underscore-dangle
  return Boolean(
    storePromoCodeFreeShippingFragment &&
      typeof storePromoCodeFreeShippingFragment === 'object' &&
      storePromoCodeFreeShippingFragment.__typename === 'PromoCodeFreeShipping'
  );
}

/**
 * Simple type guard for StorePromoCodeFixedValueFragment
 *
 * @param candidate The maybe a StorePromoCodeFixedValueFragment to test
 * @returns true if the candidate appears to be a StorePromoCodeFixedValueFragment
 */
export function isStorePromoCodeFixedValueFragment(candidate: any): candidate is StorePromoCodeFixedValueFragment {
  const storePromoCodeFixedValueFragment: StorePromoCodeFixedValueFragment =
    candidate as StorePromoCodeFixedValueFragment;
  // eslint-disable-next-line no-underscore-dangle
  return Boolean(
    storePromoCodeFixedValueFragment &&
      typeof storePromoCodeFixedValueFragment === 'object' &&
      storePromoCodeFixedValueFragment.__typename === 'PromoCodeFixedValue'
  );
}

/**
 * Simple type guard for CollectionsV2Fragment
 *
 * @param candidate The maybe a CollectionsV2Fragment to test
 * @returns true if the candidate appears to be a CollectionsV2Fragment
 */
export function isCollectionsV2Fragment(candidate: any): candidate is CollectionsV2Fragment {
  const collectionsFragment: CollectionsV2Fragment = candidate as CollectionsV2Fragment;
  // eslint-disable-next-line no-underscore-dangle
  return Boolean(
    collectionsFragment && typeof collectionsFragment === 'object' && collectionsFragment.__typename === 'CollectionsV2'
  );
}

/**
 * Simple type guard for CollectionsQueryErrorResultFragment
 *
 * @param candidate The maybe a CollectionsQueryErrorResultFragment to test
 * @returns true if the candidate appears to be a CollectionsQueryErrorResultFragment
 */
export function isCollectionsQueryErrorResultFragment(
  candidate: any
): candidate is CollectionsQueryErrorResultFragment {
  const collectionsQueryErrorResultFragment: CollectionsQueryErrorResultFragment =
    candidate as CollectionsQueryErrorResultFragment;
  // eslint-disable-next-line no-underscore-dangle
  return Boolean(
    collectionsQueryErrorResultFragment &&
      typeof collectionsQueryErrorResultFragment === 'object' &&
      collectionsQueryErrorResultFragment.__typename === 'CollectionsQueryErrorResult'
  );
}

/**
 * Simple type guard for CheckoutFragment
 *
 * @param candidate The maybe a CheckoutFragment to test
 * @returns true if the candidate appears to be a CheckoutFragment
 */
export function isCheckoutFragment(candidate: any): candidate is CheckoutFragment {
  const checkoutFragment: CheckoutFragment = candidate as CheckoutFragment;
  // eslint-disable-next-line no-underscore-dangle
  return Boolean(
    checkoutFragment && typeof checkoutFragment === 'object' && checkoutFragment.__typename === 'Checkout'
  );
}

/**
 * Simple type guard for PaymentIntentErrorResultFragment
 *
 * @param candidate The maybe a PaymentIntentErrorResultFragment to test
 * @returns true if the candidate appears to be a PaymentIntentErrorResultFragment
 */
export function isPaymentIntentErrorResultFragment(candidate: any): candidate is PaymentIntentErrorResultFragment {
  const paymentIntentErrorResultFragment: PaymentIntentErrorResultFragment =
    candidate as PaymentIntentErrorResultFragment;
  // eslint-disable-next-line no-underscore-dangle
  return Boolean(
    paymentIntentErrorResultFragment &&
      typeof paymentIntentErrorResultFragment === 'object' &&
      paymentIntentErrorResultFragment.__typename === 'PaymentIntentErrorResult'
  );
}

/**
 * Simple type guard for CheckoutErrorFragment
 *
 * @param candidate The maybe a CheckoutErrorFragment to test
 * @returns true if the candidate appears to be a CheckoutErrorFragment
 */
export function isCheckoutErrorFragment(candidate: any): candidate is CheckoutErrorFragment {
  const checkoutErrorFragment: CheckoutErrorFragment = candidate as CheckoutErrorFragment;
  // eslint-disable-next-line no-underscore-dangle
  return Boolean(
    checkoutErrorFragment &&
      typeof checkoutErrorFragment === 'object' &&
      checkoutErrorFragment.__typename === 'CheckoutError'
  );
}

/**
 * Simple type guard for DeliveryMinervaErrorsFragment
 *
 * @param candidate The maybe a DeliveryMinervaErrorsFragment to test
 * @returns true if the candidate appears to be a DeliveryMinervaErrorsFragment
 */
export function isDeliveryMinervaErrorsFragment(candidate: any): candidate is DeliveryMinervaErrorsFragment {
  const deliveryMinervaErrorsFragment: DeliveryMinervaErrorsFragment = candidate as DeliveryMinervaErrorsFragment;
  // eslint-disable-next-line no-underscore-dangle
  return Boolean(
    deliveryMinervaErrorsFragment &&
      typeof deliveryMinervaErrorsFragment === 'object' &&
      deliveryMinervaErrorsFragment.__typename === 'DeliveryMinervaErrors'
  );
}

/**
 * Simple type guard for CheckoutUnprocessableErrorsFragment
 *
 * @param candidate The maybe a CheckoutUnprocessableErrorsFragment to test
 * @returns true if the candidate appears to be a CheckoutUnprocessableErrorsFragment
 */
export function isCheckoutUnprocessableErrorsFragment(
  candidate: any
): candidate is CheckoutUnprocessableErrorsFragment {
  const checkoutUnprocessableErrorsFragment: CheckoutUnprocessableErrorsFragment =
    candidate as CheckoutUnprocessableErrorsFragment;
  // eslint-disable-next-line no-underscore-dangle
  return Boolean(
    checkoutUnprocessableErrorsFragment &&
      typeof checkoutUnprocessableErrorsFragment === 'object' &&
      checkoutUnprocessableErrorsFragment.__typename === 'CheckoutUnprocessableErrors'
  );
}

/**
 * Simple type guard for ProductBlankQueryErrorResult
 *
 * @param candidate The maybe a ProductBlankQueryErrorResult to test
 * @returns true if the candidate appears to be a ProductBlankQueryErrorResult
 */
export function isProductBlankQueryErrorResult(candidate: any): candidate is ProductBlankQueryErrorResultFragment {
  const productBlankQueryErrorResult: ProductBlankQueryErrorResultFragment =
    candidate as ProductBlankQueryErrorResultFragment;
  // eslint-disable-next-line no-underscore-dangle
  return Boolean(
    productBlankQueryErrorResult &&
      typeof productBlankQueryErrorResult === 'object' &&
      productBlankQueryErrorResult.__typename === 'ProductBlankQueryErrorResult'
  );
}
