import { isNullish } from '@/lib/utils';

/**
 * Search a string for another string and return the substring before the match, returns empty string on miss.
 *
 * @param {*} str String to search in
 * @param {*} search String to search for
 * @param {*} start Character index to start looking at
 * @returns {string}
 */
export function stringBefore(str: string, search: string, start: number = 0): string {
  const idx = str && search ? str.indexOf(search, start) : -1;
  return idx > 0 ? str.substring(start, idx) : '';
}

/**
 * Appends suffix to string str iff str does not already end in suffix
 *
 * @param {*} str String we want to assure ends in char c
 * @param {*} suffix String to assure the string ends in (does not do partial matches)
 * @returns {string}
 */
export function assureEndsWith(str: string, suffix: string): string {
  if (!suffix || str.endsWith(suffix)) return str;
  return str + suffix;
}

/**
 * Remove a single leading forward slash from str, if present
 *
 * @param {*} str The str to remove a potential leading slash from
 * @returns The string without leading forward slash, or str of no leading slash was found
 */
export function stripLeadingSlash(str: string): string {
  return str && str.charAt(0) === '/' ? str.substring(1) : str;
}

/**
 * Remove all trailing forward slashes from str, if present
 *
 * @param str  The str to remove trailing slashes from
 * @returns The string without trailing forward slashes, or str of no trailing slash was found
 */
export function stripTrailingSlashes(str: string) {
  return str ? str.replace(/\/+$/, '') : str;
}

/**
 * path.join that always uses forward slashes
 *
 * @param paths
 * @returns {string}
 */
export function joinSlash(...paths: string[]): string {
  // use reduce to merge N paths
  return paths.reduce((left, right) => {
    // ignore junk input (including first call)
    if (!left) return right || '';
    if (!right) return left;

    // check bits we want to smush together for existing slashes
    const leftSlash = left.endsWith('/');
    const rightSlash = right.startsWith('/');

    // smush path bits in such a way to only have one slash (except if an input had repeated slashes)
    if (leftSlash && rightSlash) {
      return `${left}${right.substring(1)}`;
    } else if (leftSlash || rightSlash) {
      return `${left}${right}`;
    } else {
      return `${left}/${right}`;
    }
  });
}

/**
 * Capitalizes First letter and copies the rest
 * @param {string} str
 * @returns {string}
 */
export function capitalizeFirstChar(str: string): string {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
}

/**
 * Return true if string is wrapped in matching single or double quotes
 *
 * @param str
 * @returns {boolean}
 */
export function isQuoted(str: string): boolean {
  return Boolean(
    str && str.length > 1 && ((str.startsWith('"') && str.endsWith('"')) || (str.startsWith('\'') && str.endsWith('\''))) // prettier-ignore
  );
}

/**
 * Remove wrapping quotes from string
 *
 * @param str
 * @returns {string}
 */
export function dequote(str: string): string {
  return isQuoted(str) ? str.substring(1, str.length - 1) : str;
}

/**
 * Similar to c strcmp(), returns:
 *   < 0 if a is less than b
 *   > 0 of a is greater than b
 *   0 if they are equal
 * note this compares character codes, and is not locale aware
 * @param a
 * @param b
 */
export function stringCompare(a: string, b: string) {
  // would be nice if there were a simple single walk that would return the difference, but
  // that does not appear to be the case, so do up to two comparisons and assume the engine
  // compare code is more than twice as fast us use trying to do the compare in js
  if (a < b) {
    return -1;
  } else if (a > b) {
    return 1;
  } else {
    return 0;
  }
}

const KB_BYTES = 1024;
const MB_BYTES = KB_BYTES * KB_BYTES;
const GB_BYTES = MB_BYTES * KB_BYTES;
const TB_BYTES = GB_BYTES * KB_BYTES;
const PB_BYTES = TB_BYTES * KB_BYTES;

/**
 * Convert bytes to a shorter pretty string with units
 * @param bytes
 * @param space Insert a space between the number and the units?
 * @returns String with the size in human readbable units
 */
export function prettyBytes(bytes: number, space = false) {
  let prefix = '';
  if (bytes < 0) {
    prefix = '-';
    bytes *= -1; // eslint-disable-line no-param-reassign
  }

  let n;
  let u;
  let s = space;
  if (bytes >= PB_BYTES) {
    n = bytes / PB_BYTES;
    u = 'PB';
  } else if (bytes >= TB_BYTES) {
    n = bytes / TB_BYTES;
    u = 'TB';
  } else if (bytes >= GB_BYTES) {
    n = bytes / GB_BYTES;
    u = 'GB';
  } else if (bytes >= MB_BYTES) {
    n = bytes / MB_BYTES;
    u = 'MB';
  } else if (bytes >= KB_BYTES) {
    n = bytes / KB_BYTES;
    u = 'KB';
  } else {
    n = bytes;
    u = bytes === 1 ? 'byte' : 'bytes';
    s = true; // always show space for byte(s)
  }

  return `${prefix}${parseFloat(n.toFixed(2)).toLocaleString()}${s ? ' ' : ''}${u}`;
}

/**
 * Helper to convert a string to an integer with tunable default value
 * @param str
 * @param defaultValue
 * @returns
 */
export function stringToInt(str?: string | null, defaultValue?: number): number | undefined {
  if (isNullish(str)) {
    return defaultValue;
  }
  const v = parseInt(str, 10);
  if (Number.isNaN(v)) {
    return defaultValue;
  }
  return v;
}

/**
 * Truncates the given text to a specified maximum character count and appends an ellipsis (…)
 * if the text is longer than the allowed limit.
 *
 * @param text The input text to be truncated.
 * @param maxCharCount The maximum number of characters allowed in the text.
 * @returns The truncated text, ending with an ellipsis if truncation occurred.
 */
export function truncateText(text: string, maxCharCount: number): string {
  if (maxCharCount <= 0 || text.length <= maxCharCount) {
    return text; // Return the original text if it's within the limit or invalid maxCharCount
  }

  // Adjust the truncation length to account for the ellipsis
  const adjustedMaxLength = maxCharCount - 1;
  return `${text.slice(0, adjustedMaxLength)}…`;
}
